let posthogModule = null;

export const getPosthogModule = async () => {
  if (posthogModule) {
    return new Promise((resolve) => resolve(posthogModule));
  }
  return import('posthog-js').then((module) => {
    const { posthog } = module;
    posthogModule = posthog;
    return posthog;
  });
};
