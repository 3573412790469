import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { createContext, useContext, useState } from 'react';
import config from '@/utility/config';

export const GoogleLoginContext = createContext({});

export const GoogleLoginProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  const value = {
    useGoogleLogin,
    isReady
  };

  return (
    <GoogleOAuthProvider
      clientId={config.googleClientId}
      onScriptLoadSuccess={() => {
        setIsReady(true);
      }}>
      <GoogleLoginContext.Provider value={value}>
        {children}
      </GoogleLoginContext.Provider>
    </GoogleOAuthProvider>
  );
};

export const useGoogleLoginContext = () => useContext(GoogleLoginContext);
