/* eslint-disable no-storage/no-browser-storage */
let localStorageInstance = null;

//in anonymous mode of browsers, we are unable to save data in localStorage
//in some cases, users' browsers don't allow localStorage either
//so we need to replicate localStorage and keep all changes in local data
class LocalStorageService {
  constructor() {
    if (!localStorageInstance) {
      this.currentLocalStorage = {};
      localStorageInstance = this;
    }

    return localStorageInstance;
  }

  isSupported() {
    try {
      const key = '_this_key_should_not_use_anywhere_';
      const value = '_this_value_should_not_use_anywhere_';
      if (typeof window === 'undefined' || !window.localStorage) {
        return false;
      }
      window.localStorage.setItem(key, value);
      window.localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  getItem(key) {
    if (this.isSupported()) {
      return (
        window.localStorage.getItem(key) || this.currentLocalStorage[key]
      );
    }
    return this.currentLocalStorage[key];
  }

  setItem(key, value) {
    if (this.isSupported()) {
      window.localStorage.setItem(key, value);
    }
    this.currentLocalStorage[key] = value;
  }

  removeItem(key) {
    if (this.isSupported()) {
      window.localStorage.removeItem(key);
    }
    delete this.currentLocalStorage[key];
  }

  clear() {
    if (this.isSupported()) {
      window.localStorage.clear();
    }
    this.currentLocalStorage = {};
  }

  length() {
    if (this.isSupported()) {
      return window.localStorage.length;
    }
    return Object.keys(this.currentLocalStorage).length;
  }
}

export default new LocalStorageService();
