import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import type { OptionsType } from 'cookies-next/lib/types';

import { isLocalHost } from './serverHelpers';
import { getPrimaryDomain } from './stringHelper';

class CookieService {
  static getDefaultCookieOptions(): OptionsType {
    const defaultOptions: OptionsType = {
      path: '/'
    };

    const domain = getPrimaryDomain();
    if (domain) {
      defaultOptions.domain = domain;
    }

    if (!isLocalHost()) {
      defaultOptions.secure = true;
      defaultOptions.sameSite = 'none';
    }

    return defaultOptions;
  }

  static set(key: string, value: string, options: OptionsType = {}) {
    const defaultOptions = {
      ...CookieService.getDefaultCookieOptions(),
      ...options
    };

    setCookie(key, value, defaultOptions as OptionsType);
  }

  static get(key: string, options: OptionsType = {}) {
    return getCookie(key, {
      ...CookieService.getDefaultCookieOptions(),
      ...options
    } as OptionsType);
  }

  static delete(key: string, options: OptionsType = {}) {
    deleteCookie(key, {
      ...CookieService.getDefaultCookieOptions(),
      ...options
    } as OptionsType);
  }
}

export default CookieService;
