import { useParams, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';

function useQueryParams() {
  const searchParams = useSearchParams();
  const params = useParams();
  const router = useRouter();

  const currentSearchParams: { [key: string]: string | string[] } =
    searchParams && Object.keys(searchParams)?.length > 0
      ? Object.fromEntries(searchParams.entries())
      : router.query;

  const currentDynamicParams: { [key: string]: string | string[] } =
    params || {};

  const currentRouterQuery = {
    ...currentSearchParams,
    ...currentDynamicParams
  };

  return { currentRouterQuery };
}

export default useQueryParams;
