import { useEffect, useState } from 'react';

const useFacebookChatIcon = (
  { showAlwaysHide } = { showAlwaysHide: false }
) => {
  const [isFacebookIconVisible, setIsFacebookIconVisible] =
    useState(false);
  const [isFacebookChatLoaded, setIsFacebookChatLoaded] = useState(false);

  const hideChatWidget = () => {
    setIsFacebookIconVisible(false);
  };

  const showChatWidget = () => {
    setIsFacebookIconVisible(true);
  };

  useEffect(() => {
    if (isFacebookChatLoaded) {
      if (!showAlwaysHide && isFacebookIconVisible) {
        if (global.FB?.CustomerChat) {
          global.FB.CustomerChat.show(false);
        }
      } else {
        if (global.FB?.CustomerChat) {
          global.FB.CustomerChat.hide();
        }
      }
    }
  }, [isFacebookIconVisible, showAlwaysHide, isFacebookChatLoaded]);

  // Currently not cleaning up the event listener.
  global.FB?.Event.subscribe('customerchat.load', () => {
    setIsFacebookChatLoaded(true);
  });

  return {
    hideChatWidget,
    showChatWidget,
    isFacebookIconVisible,
    setIsFacebookIconVisible
  };
};

export default useFacebookChatIcon;
