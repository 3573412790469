import { useReportWebVitals } from 'next/web-vitals';
import { useEffect, useRef } from 'react';
import sessionStorageService from '@/utility/sessionStorageService';
import config from '@/utility/config';
import { getPosthogModule } from '@/utility/posthogService';
import useParametrizedPathname from '@/hooks/router/useParametrizedPathname';

const CORE_WEB_VITALS = ['CLS', 'INP', 'LCP', 'FID'];
const POSTHOG_WEB_VITALS_EVENT = 'Web Vitals';
const REPORTED_PAGES = 'reportedPages';

// Configurable thresholds to rate limit events
const THRESHOLD_PROBABILITY =
  process.env.NODE_ENV === 'development' ? 1 : 0.3; // % of events to capture
const THRESHOLD_TIMEZONE = 0;

// Record each metric once per session
function recordReportedPage(pathname) {
  if (!sessionStorageService.getItem(REPORTED_PAGES)) {
    sessionStorageService.setItem(REPORTED_PAGES, JSON.stringify({}));
  }
  const reportedPages = JSON.parse(
    sessionStorageService.getItem(REPORTED_PAGES)
  );

  if (reportedPages) {
    reportedPages[pathname] = 1;
    sessionStorageService.setItem(
      REPORTED_PAGES,
      JSON.stringify(reportedPages)
    );
  }
}

// Check if a metric has been reported for that session
function hasPageBeenReported(pathname) {
  if (!sessionStorageService.getItem(REPORTED_PAGES)) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(
    JSON.parse(sessionStorageService.getItem(REPORTED_PAGES)),
    pathname
  );
}

export default function WebVitals() {
  const pathname = useParametrizedPathname();

  // Preserve across renders. Avoid useState to prevent re-render(s)
  // To preserve across page reloads, use session storage instead
  const shouldReportWebVitals = useRef(null);

  // Buffer already received events to wait for other events to be sent as a batch.
  // useRef to preserve across re-renders caused by client-side routing, because we only want to record on first page load
  let webVitals = useRef({});
  let capturedTime = useRef(null);

  function enqueueMetric(metric) {
    const { name } = metric;
    delete metric['entries'];
    webVitals.current = { ...webVitals?.current, [name]: metric };
  }

  async function flushWebvitals() {
    if (Object.keys(webVitals?.current).length > 0) {
      // Override auto-capture path name to match actual path where web vitals were collected
      webVitals.current = {
        $pathname: pathname,
        $timestamp: capturedTime?.current,
        ...webVitals?.current
      };
      const posthog = await getPosthogModule();
      posthog?.capture(POSTHOG_WEB_VITALS_EVENT, webVitals.current);
      webVitals.current = {};
      capturedTime.current = null;

      // Record page as being reported so to not report it again
      window.reportedOnFirstPageLoad = true;
      recordReportedPage(pathname);
    }
  }

  useEffect(() => {
    const eventId = window.addEventListener(
      'beforeunload',
      flushWebvitals
    );
    return () => window.removeEventListener('beforeunload', eventId);
  }, []);

  useReportWebVitals((metric) => {
    const { name } = metric;
    if (!CORE_WEB_VITALS?.includes(name)) {
      return;
    }

    // Not yet set. Set it once until the next full page-reload
    if (shouldReportWebVitals.current == null) {
      const d = new Date();
      const tzOffset = d.getTimezoneOffset();
      shouldReportWebVitals.current =
        tzOffset > THRESHOLD_TIMEZONE ||
        Math.random() <= THRESHOLD_PROBABILITY;
    }

    if (
      shouldReportWebVitals?.current && // rate limit - geography
      !hasPageBeenReported(pathname) && // rate limit - once per session
      config?.isPosthogEnabled && // feature flag
      !window.reportedOnFirstPageLoad && // capture only on first page load
      !webVitals?.current?.[name] // prevent overriding existing values
    ) {
      enqueueMetric(metric);
      // Time the first metric was collected - for monitoring
      if (!capturedTime?.current) {
        capturedTime.current = new Date().toISOString();
      }
    }
  });
}
