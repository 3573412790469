import { Component } from 'react';
import { showErrorToast } from '../ToastContainer';
import { t } from '@/utility/localization';
import NPLButton from '@/components/npl/NPLButton';
import ErrorDisplay from '../ErrorDisplay';
import { STATIC_ASSET_BASE_URL } from '@/utility/constants';

//note that hooks do not support error boundary, so we have to use a class-based component instead
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      sentryEventId: '',
      timestamp: new Date().toUTCString().slice(5)
    };
  }

  static getDerivedStateFromError(error) {
    showErrorToast(error);
    return { hasError: true };
  }

  async componentDidCatch(error) {
    const sentryModule = await import('@sentry/browser');
    const eventId = sentryModule.captureException(error);
    this.setState({ sentryEventId: eventId });
  }

  render() {
    const GENERIC_ERROR_IMAGE_URL = `${STATIC_ASSET_BASE_URL}/nasIO/common/png/nasio-generic-error-banner.png`;
    if (this.state.hasError) {
      return (
        <ErrorDisplay
          title={t('looks-like-something-went-wrong')}
          description={() => (
            <>
              <span className="font-semibold">Nas.io </span>
              {t('is-currently-unable-to-handle-this-request')}
            </>
          )}
          imageURL={GENERIC_ERROR_IMAGE_URL}
          renderButton={
            <NPLButton
              hierarchy="accent_primary"
              size="lg"
              buttonText={t('reload')}
              onClick={() => window.location.reload()}
            />
          }
          renderFootNote={() => (
            <>
              {this.state.sentryEventId && (
                <div className="mb-8">
                  <p className="font-medium line-clamp-4 text-body-sm text-npl-text-icon-on-light-surface-tertiary">
                    {t('event-id')} {this.state.sentryEventId}
                  </p>
                </div>
              )}
              <div>
                <p className="text-body-xs text-npl-text-icon-on-light-surface-tertiary">
                  {this.state.timestamp}
                </p>
              </div>
            </>
          )}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
