export const [
  CHECKOUT_TYPE_COURSE,
  CHECKOUT_TYPE_BUNDLE,
  CHECKOUT_TYPE_COMMUNITY
] = ['course', 'bundle', 'community'];

export const [ACTION_CREATE_BUNDLE, ACTION_CHECKOUT_BUNDLE] = [
  'create',
  'checkout'
];

// Discount types
export const [DISCOUNT_TYPE_PERCENT_OFF, DISCOUNT_TYPE_AMOUNT_OFF] = [
  'percent_off',
  'amount_off'
];

// Gift card coupon code type
export const GIFT_CODE_TYPE = 'giftCard';

// Payment Status values
export const [PAYMENT_SUCCESS, PAYMENT_FAILURE, PAYMENT_PENDING] = [
  'complete',
  'failed',
  'pending'
];

// free sponsored ondemand courses
export const FREE_ONDEMAND_COURSES = [
  'EPIDEMIC_SOUND_DESIGN',
  'VAYNER_10_STEPS_NFT'
];

// data from coupon provided in URL
export const COUPON_SESSION_KEY = 'couponQueryParam';
export const SPECIAL_REDIRECT_PERFORMED_KEY = 'specialRedirectPerformed';

// checkout session keys
export const SIGN_UP_FULL_NAME_SESSION_KEY = 'signupFullName';
export const SIGN_UP_EMAIL_SESSION_KEY = 'signupEmail';
export const SIGN_UP_PHONE_NUMBER_SESSION_KEY = 'signupPhoneNumber';
export const SIGN_UP_ID_SESSION_KEY = 'signupId';
export const USER_ID_SESSION_KEY = 'userId';
export const SIGN_UP_ACCESS_TOKEN_SESSION_KEY = 'accessToken';
export const SIGN_UP_USER_CREDITS_SESSION_KEY = 'signupUserCredits';
export const SIGN_UP_SLUG_SESSION_KEY = 'signupSlug';
export const SIGN_UP_PRICE_ID = 'signupPriceId';

export const DEFAULT_CURRENCY = 'USD';
