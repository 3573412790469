import { getPerformance } from '@firebase/performance';
import { initializeApp } from 'firebase/app';
import {
  addDoc,
  arrayUnion,
  collection, // getDocs,
  // query,
  // where
  doc,
  getDoc,
  getFirestore,
  setDoc
} from 'firebase/firestore';
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  isSupported
} from 'firebase/remote-config';
import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

import config from '@/utility/config';
import localStorageService from '@/utility/localStorageService';

const FirebaseContext = createContext();

export const useFirebaseContext = () => useContext(FirebaseContext);

export const FirebaseProvider = ({ children }) => {
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [firestore, setFirestore] = useState(null);
  const [remoteConfig, setRemoteConfig] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const firebaseConfig = config.firebaseConfig;

  // Initialize everything we need from firebase
  useEffect(() => {
    if (firebaseConfig && localStorageService.isSupported()) {
      const app = initializeApp(firebaseConfig);
      setFirebaseApp(app);
      getPerformance(app);
      setFirestore(getFirestore(app));
      setRemoteConfig(getRemoteConfig(app));
      setInitialized(true);
    }
  }, []);

  const firestoreUtils = firestore
    ? {
        createDocRef: (collectionPath, docId) =>
          doc(firestore, collectionPath, docId),
        fetchDoc: async (docRef) => {
          const docSnap = await getDoc(docRef);
          return docSnap.exists() ? docSnap.data() : null;
        },
        updateDoc: async (docRef, data, options = {}) => {
          await setDoc(docRef, data, options);
        },
        addToDocArray: (newValue) => arrayUnion(newValue),
        addDoc: async (collectionName, payload) => {
          try {
            const docRef = await addDoc(
              collection(firestore, collectionName),
              payload
            );
            return docRef;
          } catch (error) {
            console.error('>> Error adding document: ', error);
          }
        }
        // Note: Keeping here intentionally for quick access.
        // TODO: @AmanMinhas - Remove comments later.
        // getData: async (collectionName) => {
        //   const querySnapshot = await getDocs(
        //     query(
        //       collection(firestore, collectionName),
        //       where('communityId', '==', '660cfb80ba8d09480f38954a'),
        //       where('event_name', '==', 'Purchase')
        //     )
        //   );
        //   const data = querySnapshot.docs.map((doc) => doc.data());
        //   return data;
        // }
      }
    : null;

  const remoteConfigUtils = {
    getAll,
    fetchAndActivate,
    isSupported
  };

  const value = {
    initialized,
    firebaseApp,
    firestore,
    remoteConfig,
    firestoreUtils,
    remoteConfigUtils
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
