/* eslint-disable no-storage/no-browser-storage */
let sessionStorageInstance = null;

//in anonymous mode of browsers, we are unable to save data in sessionStorage
//in some cases, users' browsers don't allow sessionStorage either
//so we need to replicate localStorage and keep all changes in local data
class SessionStorageService {
  constructor() {
    if (!sessionStorageInstance) {
      this.currentSessionStorage = {};
      sessionStorageInstance = this;
    }

    return sessionStorageInstance;
  }

  isSupported() {
    try {
      const key = '_this_key_should_not_use_anywhere_';
      const value = '_this_value_should_not_use_anywhere_';
      if (typeof window === 'undefined' || !window.sessionStorage) {
        return false;
      }
      window.sessionStorage.setItem(key, value);
      window.sessionStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  getItem(key) {
    if (this.isSupported()) {
      return (
        window.sessionStorage.getItem(key) ||
        this.currentSessionStorage[key]
      );
    }
    return this.currentSessionStorage[key];
  }

  setItem(key, value) {
    // Reason for try catch here is to prevent "QuotaExceededError"
    // to crash the page
    try {
      if (this.isSupported()) {
        window.sessionStorage.setItem(key, value);
      }
      this.currentSessionStorage[key] = value;
    } catch (e) {
      console.error(e.name);
    }
  }

  removeItem(key) {
    if (this.isSupported()) {
      window.sessionStorage.removeItem(key);
    }
    delete this.currentSessionStorage[key];
  }

  clear() {
    if (this.isSupported()) {
      window.sessionStorage.clear();
    }
    this.currentSessionStorage = {};
  }

  length() {
    if (this.isSupported()) {
      return window.sessionStorage.length;
    }
    return Object.keys(this.currentSessionStorage).length;
  }
}

export default new SessionStorageService();
