import * as Tooltip from '@radix-ui/react-tooltip';
import React, { useState } from 'react';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import Icon from '@/components/npl/Icon';
import PopOverModal from '../PopOverModal';

export type NplToolTipProps = {
  children: React.ReactNode;
  text: string | React.ReactNode;
  title?: string | null;
  defaultOpenState?: boolean;
  closeToolTipOnClick?: boolean;
  wrapChildrenInButton?: boolean;
};

const NplToolTip: React.FC<NplToolTipProps> = ({
  children,
  text,
  title = null,
  defaultOpenState = false,
  closeToolTipOnClick = false,
  wrapChildrenInButton = true
}) => {
  const [open, setOpen] = useState(defaultOpenState);
  const { isGtEqMd } = useWindowWidthContext();

  return isGtEqMd ? (
    <Tooltip.Provider>
      <Tooltip.Root open={open} delayDuration={150} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          {wrapChildrenInButton ? (
            <button
              className="cursor-default "
              onClick={(e) => {
                e.preventDefault(); // stop form submission if tooltip is in a form
                setOpen(true);
                if (closeToolTipOnClick) {
                  setOpen(false);
                }
              }}>
              {children}
            </button>
          ) : (
            children
          )}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="leading-none text-wrap z-over-intercom max-w-[350px] select-none rounded-[8px] bg-npl-text-icon-on-light-surface-primary px-[12px] py-[8px] text-body-sm text-npl-text-icon-on-dark-primary shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
            sideOffset={5}>
            {text}
            <Tooltip.Arrow className="fill-npl-text-icon-on-light-surface-primary" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  ) : (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}>
        {children}
      </button>
      <PopOverModal
        showCloseIcon={false}
        open={open}
        onClose={() => setOpen(false)}>
        <div className="mx-auto">
          <div className="flex items-center justify-center h-32 pt-12 border-b px border-npl-neutral-light-solid-4 pb-28">
            <div className="flex-1 font-semibold text-center text-label-lg text-npl-text-icon-on-light-surface-primary ">
              {title}
            </div>
            <div
              onClick={() => setOpen(false)}
              className="flex items-center justify-center w-32 h-32 rounded-full hover:cursor-pointer hover:bg-npl-neutral-light-solid-3">
              <Icon
                name="x-close"
                width={16}
                height={16}
                fill="text-npl-text-icon-on-light-surface-tertiary"
              />
            </div>
          </div>
          <p className="pt-12 pb-32 text-body-md text-npl-text-icon-on-light-surface-primary">
            {text}
          </p>
        </div>
      </PopOverModal>
    </>
  );
};

export const withTooltip = (
  tooltip: string | NplToolTipProps | null | undefined,
  children: React.ReactNode
) => {
  if (tooltip) {
    return (
      <NplToolTip
        text={typeof tooltip === 'string' ? tooltip : tooltip?.text}
        closeToolTipOnClick>
        {children}
      </NplToolTip>
    );
  }
  return children;
};

export default NplToolTip;
