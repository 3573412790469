import config from '@/utility/config';

export const defaultWaitlistSuccessMsg =
  'We’ll let you know as soon as the next class is available so keep an eye on your emails.';

export const themes = {
  light: '',
  dark: 'theme-dark'
};

export const mobileHeaderHeight = 64;
export const desktopHeaderHeight = 80;

export const resetPasswordLink = `${config.memberPortalPath}/forget-password`;

export const recoverPasswordLink = `${config.memberPortalPath}/recover-password`;
