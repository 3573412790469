import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const useAos = (props = {}) => {
  useEffect(() => {
    AOS.init(props);
  });

  return {};
};

export default useAos;
