export const communitySubscriptionStatus = {
  all: '',
  interested: 'Interested', //for leads data
  pending: 'Pending',
  current: 'Current',
  cancelled: 'Cancelled',
  rejected: 'Rejected',
  unsubscribingMember: 'Unsubscribing',
  invited: 'Invited',
  removed: 'Removed',
  paymentFailed: 'PaymentFailed',
  subscriber: 'Subscriber'
};

export const uiConfigDisplayType = {
  combination: 'combination', //it's a combination of primary and secondary values. Eg. 1 day (1 - primary, day - secondary)
  mapping: 'mapping', //it will go along with mappingValues defined in config
  string: 'string',
  number: 'number',
  statusPill: 'statusPill',
  interestedInBadge: 'interestedInBadge',
  custom: 'custom',
  simplePrice: 'simplePrice',
  simplePricePositiveOnly: 'simplePricePositiveOnly',
  date: 'date', // Render date in this format -> 22 May 2022.
  timeAgo: 'timeAgo', // seconds, minutes, hourse, days, months, years.
  radio: 'radio',
  contact: 'contact', //email (primary) and phone number (secondary)
  email: 'email', // just email (primary)
  nameWithAvatar: 'nameWithAvatar', //name (primary) and avatar (secondary)
  nameAvatarEmailDateCombination: 'nameAvatarEmailDateCombination', // For event management page mobile view
  nameAvatarBadgeCombination: 'nameAvatarBadgeCombination',
  nameAvatarEmailBadgeDateCombination:
    'nameAvatarEmailBadgeDateCombination',
  // in case of radio, share of value will be an object as follows. the output should be a string in label key.
  // {
  //   value: 'application-criteria-mismatch',
  //   label: 'Application criteria was not met'
  // }
  // OR
  // {
  //   value: 'other',
  //   label: 'Application not satisfactory'
  // }
  //
  checkbox: 'checkbox',
  // checkbox field data format. Need to display all labels as bullet points.
  // [
  //   {
  //     value: 'learn-form-others',
  //     label: 'Learn from fellow content creators'
  //   },
  //   {
  //     value: 'build-toolkit',
  //     label: 'Build a toolkit of tactics to create better content'
  //   }
  // ]
  price: 'price',
  // In case of price field. expect fieldDisplayMetadata.currencyField for currency (USD Default). use fieldName for amount. Output ex. 29 USD
  dateDiff: 'dateDiff',
  // In case of dateDiff field. expect fieldDisplayMetadata: {
  //   startDateField: 'createdAt',
  //   endDateField: 'updatedAt'
  // } for calculating diff. if endDateField is empty, use Today.
  boolean: 'boolean',
  link: 'link',
  nameWithChip: 'nameWithChip',
  whatsappLastActivity: 'whatsappLastActivity',
  // 1 on 1 session timeRange
  timeRange: 'timeRange',
  // Example: 20 Feb 2024
  dateShort: 'dateShort',
  // Icon infront with string
  nameWithPaymentLeadIcon: 'nameWithPaymentLeadIcon',
  nameWithPaymentLeadIconAndDate: 'nameWithPaymentLeadIconAndDate'
};

export const communityApplicationStatus = {
  approved: 'Approved',
  rejected: 'Rejected',
  pending: 'Pending',
  unsubscribed: 'Unsubscribed'
};

//Enhance: Maintain sequence of uiConfig according to config
export const generateApplicationDisplayConfig = ({ config, data }) => {
  try {
    //1. check if data field is present on the config or not
    //2. if present add to ui config list

    const configFieldObj = config.reduce((acc, field, index) => {
      return { ...acc, [field.fieldName]: { index, ...field } };
    }, {});

    const dataKeys = Object.keys(data);

    const requiredUIConfig = dataKeys.reduce((acc, key) => {
      const currentAcc = [...acc];
      const existInConfig = !!configFieldObj[key];

      if (existInConfig) currentAcc.push(configFieldObj[key]);

      return currentAcc;
    }, []);

    //to maintain the sequence of config
    return requiredUIConfig.sort((a, b) => a.index - b.index);
  } catch (e) {
    console.error(e);
    return [];
  }
};
