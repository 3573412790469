import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';

import { trackGAEventWithCommunityAndUser } from '@/utility/analytics';
import { addSentryBreadcrumb } from '@/utility/sentryService';
import withComponentClassName, {
  WithComponentClassName
} from '@/utility/withComponentClassName';

import {
  NplToolTipProps,
  withTooltip
} from '@/components/common/NplToolTip';

import Icon from '../Icon';
import type { IconName, IconNameWithPath } from '../Icon';
import { buttonStyles } from '../constants';
import type {
  NPLIconButtonHierarchyType,
  NPLIconButtonSizeType
} from './types';
import { getIconSizeByButtonSize, getPaddingClassBySize } from './utils';

export type NPLIconButtonProps = WithComponentClassName<{
  hierarchy: NPLIconButtonHierarchyType;
  icon: string | IconNameWithPath;
  size: NPLIconButtonSizeType;
  classname?: string;
  darkMode?: boolean;
  dataTestId?: string;
  disabled?: boolean;
  link?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string | NplToolTipProps;
}>;

const NPLIconButton: React.FC<NPLIconButtonProps> = (props) => {
  const {
    icon,
    hierarchy,
    onClick,
    disabled,
    loading,
    size,
    componentClassName,
    darkMode,
    tooltip,
    link,
    dataTestId,
    classname
  } = props;

  const iconSize = getIconSizeByButtonSize(size);

  const colorMode = darkMode ? 'dark' : 'light';
  const disabledMode = disabled ? 'disabled' : 'enabled';
  const populatedColorStyles =
    buttonStyles[hierarchy][colorMode][disabledMode];

  const iconName =
    typeof icon === 'string' ? (icon as IconName) : icon.name;
  const iconPath = typeof icon === 'string' ? undefined : icon.path;

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    trackGAEventWithCommunityAndUser('npl-button-clicked', {
      buttonProps: props
    });

    addSentryBreadcrumb(
      `NPLIconButton clicked: ${
        dataTestId ? ` | data-test-id: (${dataTestId})` : ''
      }`
    );

    if (onClick) {
      onClick(e);
    }
  };

  const Element: React.ElementType = link && !disabled ? Link : 'button';

  return withTooltip(
    tooltip,
    <Element
      disabled={disabled || loading}
      onClick={handleButtonClick}
      data-test-id={dataTestId}
      {...(link && !disabled ? { href: link } : {})}
      className={classNames(
        componentClassName,
        'rounded-full self-center relative',
        getPaddingClassBySize(size),
        !disabled ? 'cursor-pointer' : 'cursor-auto',
        populatedColorStyles.background
      )}>
      <Icon
        name={iconName}
        path={iconPath}
        width={iconSize}
        height={iconSize}
        className={classNames(
          populatedColorStyles.content,
          {
            'opacity-0': loading
          },
          classname
        )}
      />
      {loading && (
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full rounded-100">
          <div className="animate-[spin_2s_linear_infinite] w-[17px] h-[17px]">
            <Icon
              name="spinning-wheel"
              width={20}
              height={20}
              fillOpacity={0.5}
              fill={
                hierarchy === 'neutral_primary' ? '#F5F5F5' : '#1B1B18'
              }
            />
          </div>
        </div>
      )}
    </Element>
  );
};

export default withComponentClassName(NPLIconButton, false);
