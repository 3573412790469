import { platformTypes } from '@/utility/constants';

export const SIGNUP_FORM_STATE = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  SUCCESS: 'SUCCESS',
  ONE_CLICK: 'ONE_CLICK',
  EMAIL_WITH_SOCIAL_LOGIN: 'EMAIL_WITH_SOCIAL_LOGIN',
  LOGIN_WITH_PASSWORD: 'LOGIN_WITH_PASSWORD',
  OTP: 'OTP',
  PASSWORD_CREATION: 'PASSWORD_CREATION'
};

export const TRACKING_COMMUNITY_TYPE = {
  FREE: 'free',
  PAID: 'paid',
  NFT: 'nft'
};

export const SIGNUP_REQUESTOR = 'signupRequestor';
export const WHATSAPP_SIGNUP_REQUESTOR = 'whatsappSignup';

export const SIGNUP_ORIGIN = {
  LANDING_PAGE: 'landing-page',
  CHALLENGE_PAGE: 'challenge-page',
  EVENT_PAGE: 'event-page',
  RESOURCE_PAGE: 'resource-page',
  POST_PAGE: 'post-page',
  ONE_ON_ONE_SESSION_PAGE: 'one-on-one-session'
};

export const ONE_ON_ONE_SESSION_ACTION_TYPE = 'session';

export const CTA_PLATFORM_TEXTS = {
  [platformTypes.whatsapp]: 'Join WhatsApp Group',
  [platformTypes.discord]: 'Open Discord Server',
  [platformTypes.telegram]: 'Open Telegram Group',
  [platformTypes.facebook]: 'Open Facebook Group',
  [platformTypes.linkedin]: 'Open LinkedIn Group',
  [platformTypes.slack]: 'Open Slack Channel'
};
