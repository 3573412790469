import type { OptionsType } from 'cookies-next/lib/types';
import { Locale, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import es from 'date-fns/locale/es';
import ja from 'date-fns/locale/ja';
import pt from 'date-fns/locale/pt';
import type { NextPageContext } from 'next';

import config from '@/utility/config';

import english from '../../locales/en.json';
import CookieService from '../cookieService';
import { isValidDate } from '../dateHelper';
import { getDomainByHostName } from '../stringHelper';
import {
  DEFAULT_ES_LOCALE,
  ES_SHORT_CODE_LOCALE,
  LOCALIZED_DATE_TIME_FORMAT,
  NEXT_LOCALE_KEY,
  defaultLocale,
  getSupportedLocaleOptionsFromModule
} from './constants';
import { AllowedDateTimeFormatType, SupportedLocaleType } from './types';

const currentServerTranslationKeys: string[] = [];

export const getOnlyServerTranslationKeys = (
  currentLanguageData: Record<string, string>
): Record<string, string> => {
  if (typeof window !== 'undefined') {
    return currentLanguageData;
  }

  const entries = Object.entries(currentLanguageData).filter(([key]) =>
    currentServerTranslationKeys.includes(key)
  );
  return Object.fromEntries(entries);
};

interface LocalizationData {
  _languageData: Record<string, string>;
  _locale: string;
  languageData: Record<string, string>;
  locale: string;
}

const localizationData: LocalizationData = {
  _languageData: english,
  _locale: defaultLocale,
  get languageData() {
    return typeof window !== 'undefined' &&
      window.localizationData?.languageData
      ? window.localizationData.languageData
      : this._languageData;
  },
  set languageData(updatedLanguageData: Record<string, string>) {
    this._languageData = updatedLanguageData;
    if (typeof window !== 'undefined') {
      if (!window.localizationData) {
        window.localizationData = {
          languageData: updatedLanguageData,
          locale: this._locale
        };
      }
      window.localizationData.languageData = updatedLanguageData;
    }
  },
  get locale() {
    const populatedLocale =
      typeof window !== 'undefined' && window.localizationData?.locale
        ? window.localizationData.locale
        : this._locale;

    return populatedLocale ? populatedLocale : defaultLocale;
  },
  set locale(updatedLocale: SupportedLocaleType) {
    this._locale = updatedLocale;
    if (typeof window !== 'undefined') {
      if (!window.localizationData) {
        window.localizationData = {
          languageData: this._languageData,
          locale: updatedLocale
        };
      }
      window.localizationData.locale = updatedLocale;
    }
  }
};

export const importDateTimeFormatWithLocale = (currentLocale?: string) => {
  const locale = currentLocale || defaultLocale;

  const [language] = locale.split('-');
  //TODO: Change this to dynamic import if we have more dynamic locales
  const dateTimeFormatWithLocale: Record<string, unknown> = {
    es: es,
    pt: pt,
    ja: ja
  };
  return dateTimeFormatWithLocale[language];
};

export const getSupportedLocaleOptions =
  getSupportedLocaleOptionsFromModule;

export const getCurrentLanguageLabel = (
  currentLocale?: string
): string | undefined => {
  if (
    getSupportedLocaleOptions().every(
      (supportedLocale) =>
        supportedLocale.value !== currentLocale &&
        supportedLocale.shortCode !== currentLocale
    )
  ) {
    currentLocale = defaultLocale;
  }
  const locale = currentLocale || localizationData.locale;
  const currentLanguageOption = getSupportedLocaleOptions().find(
    ({ value }) => value === locale.toLowerCase()
  );
  return currentLanguageOption?.label;
};

export const getShortCodeByLocale = (currentLocale: string): string => {
  const localeOptions = getSupportedLocaleOptions();
  const foundLocaleOption = localeOptions.find(
    (option) => option.value === currentLocale
  );
  const displayedLocale = foundLocaleOption?.shortCode || currentLocale;
  return displayedLocale;
};

export const extractLocaleFromAcceptLanguage = (
  acceptLanguage?: string
): string => {
  if (!acceptLanguage) {
    return '';
  }

  const extractedLanguage = acceptLanguage
    .split(',')
    .map((i) => i.split(';'))
    ?.reduce(
      (result: { code: string; priority: string }[], lang: string[]) => [
        ...result,
        { code: lang[0], priority: lang[1] }
      ],
      []
    )
    ?.sort((a, b) => (a.priority > b.priority ? -1 : 1))[0]
    ?.code.toLowerCase();

  return extractedLanguage.includes(defaultLocale)
    ? defaultLocale
    : extractedLanguage;
};

export const getBrowserLocale = (): string => {
  //we cannot get browser locales with server-side rendering
  if (typeof window === 'undefined' || !navigator) {
    return '';
  }

  const browserLocale = navigator.languages
    ? navigator.languages[0]
    : navigator.language;

  const isDefinedLocale =
    browserLocale &&
    (getSupportedLocaleOptions().some(
      ({ value }) => value === browserLocale.toLowerCase()
    ) ||
      browserLocale.includes('es'));
  if (isDefinedLocale) {
    if (browserLocale === defaultLocale) {
      return '';
    }

    return browserLocale.includes('es')
      ? DEFAULT_ES_LOCALE
      : browserLocale.toLowerCase();
  }

  return '';
};

export const getLocaleFromCookie = (
  ctx?: NextPageContext
): string | undefined => {
  const nextLocaleKey = NEXT_LOCALE_KEY;
  const storedLocale = CookieService.get(nextLocaleKey, ctx);
  return storedLocale as string | undefined;
};

export const setLocaleToCookie = (
  localeValue: string,
  context?: NextPageContext
) => {
  const host = context?.req
    ? context.req.headers?.host
    : location?.hostname;

  if (
    getSupportedLocaleOptions().every(
      (supportedLocale) =>
        supportedLocale.value !== localeValue &&
        supportedLocale.shortCode !== localeValue
    )
  ) {
    localeValue = defaultLocale;
  }

  //clean up the cookie which is not attached with sub-domains
  CookieService.delete(NEXT_LOCALE_KEY);

  const domain = getDomainByHostName(host);
  const cookieOptions: OptionsType = {
    ...context
  };
  if (domain) {
    cookieOptions.domain = domain;
  }

  CookieService.set(NEXT_LOCALE_KEY, localeValue, cookieOptions);
};

export const fetchLanguageDataByLocale = async (
  currentLocale: string
): Promise<Record<string, string>> => {
  if (
    !currentLocale ||
    getSupportedLocaleOptions().every(
      (supportedLocale) =>
        supportedLocale.value !== currentLocale &&
        supportedLocale.shortCode !== currentLocale
    )
  ) {
    currentLocale = defaultLocale;
  }

  if (currentLocale === ES_SHORT_CODE_LOCALE) {
    currentLocale = DEFAULT_ES_LOCALE;
  }

  if (!config.localeFilePath) {
    return english;
  }

  try {
    const localeJsonPath = `${config.localeFilePath}${currentLocale.toLowerCase()}.json?t=${Date.now()}`;
    const res = await fetch(localeJsonPath);
    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Cannot fetch language data', error);
    return english;
  }
};

export const initializeLanguageData = (
  currentLanguageData: Record<string, string>,
  currentLocale: string,
  isServer: boolean
): Record<string, string> => {
  const updatedLanguageData = isServer
    ? getOnlyServerTranslationKeys(currentLanguageData)
    : currentLanguageData;

  const filteredKeys = updatedLanguageData ?? {};
  localizationData.languageData = filteredKeys;
  localizationData.locale = currentLocale;
  return filteredKeys;
};

//t is the shorthand of `translate`
//I don't explicitly call `translate` because it has the same name as CSS's attribute name
export const t = (
  key: string,
  params: Record<string, string | number> = {}
): string => {
  if (!key) {
    return key;
  }

  const languageData = localizationData.languageData;
  let translatedValue = languageData[key];
  if (!translatedValue) {
    translatedValue = english[key] || key;
    if (typeof window !== 'undefined') {
      console.warn(
        `Missing translation for key: ${key} | English: ${english[key]}`
      );
    }
  }

  if (typeof window === 'undefined') {
    currentServerTranslationKeys.push(key);
  }

  if (translatedValue && Object.keys(params).length) {
    Object.keys(params).forEach((paramKey) => {
      const paramKeyRegex = new RegExp(`{{${paramKey}}}`, 'gi');
      translatedValue = translatedValue.replace(
        paramKeyRegex,
        String(params[paramKey]) ?? ''
      );
    });
  }

  return translatedValue;
};

const reloadPageWithLocale = (newLocale: string) => {
  const currentPathName = window.location.pathname;
  let pathParts = currentPathName.split('/');

  const localeOptions = getSupportedLocaleOptions();

  if (pathParts.length === 2 && pathParts[1] === newLocale) {
    return;
  }

  if (
    localeOptions.some(({ value }) => pathParts[1] === value) &&
    newLocale === defaultLocale
  ) {
    pathParts.splice(0, 2);
  }

  if (
    localeOptions.some(({ value }) => pathParts[1] === value) &&
    newLocale !== defaultLocale
  ) {
    pathParts[1] = newLocale;
  }

  if (
    localeOptions.every(({ value }) => pathParts[1] !== value) &&
    newLocale !== defaultLocale
  ) {
    pathParts.splice(0, 1);
    pathParts = ['', newLocale, ...pathParts];
  }

  if (
    localeOptions.every(({ value }) => pathParts[1] !== value) &&
    newLocale === defaultLocale
  ) {
    pathParts = ['', ...pathParts];
  }

  const pathname = pathParts.join('/');
  const checkedPathName = pathname === '/' ? '' : pathname;

  window.location.href = origin + checkedPathName + window.location.search;
};

export const setLocale = (newLocale: string, isUrlChanged?: boolean) => {
  setLocaleToCookie(newLocale);
  if (typeof window !== 'undefined') {
    if (isUrlChanged) {
      reloadPageWithLocale(newLocale);
    } else {
      window.location.reload();
    }
  }
};

export const isValidLocale = (locale: string): boolean => {
  const validLocales = getSupportedLocaleOptions().map((localOption) =>
    localOption.value.toLowerCase()
  );

  validLocales.push('es'); // add es as a valid locale.

  return validLocales.includes(locale.toLowerCase());
};

interface CurrentActiveLocaleParams {
  acceptLanguageLocale: string;
  storedLocale: string;
  urlLocale: string;
}

export const getCurrentActiveLocale = ({
  storedLocale,
  acceptLanguageLocale,
  urlLocale
}: CurrentActiveLocaleParams): string => {
  let currentLocale = storedLocale || acceptLanguageLocale || urlLocale;
  currentLocale = currentLocale.startsWith(ES_SHORT_CODE_LOCALE)
    ? DEFAULT_ES_LOCALE
    : currentLocale;

  currentLocale = isValidLocale(currentLocale)
    ? currentLocale
    : defaultLocale;

  return currentLocale;
};

export const getCurrentLocale = () => {
  if (typeof window !== 'undefined') {
    return localizationData.locale;
  }

  const storedLocale = CookieService.get(NEXT_LOCALE_KEY);

  return storedLocale || defaultLocale;
};

export const formatDateTimeWithLocale = (
  time: Date | number | string,
  formatter: AllowedDateTimeFormatType,
  utc = false
): string => {
  if (!time) {
    return '';
  }

  if (!isValidDate(time.toString())) {
    return time.toString();
  }
  const dateTimeLocale = importDateTimeFormatWithLocale(
    localizationData.locale
  ) as Locale | undefined;

  if (LOCALIZED_DATE_TIME_FORMAT[localizationData.locale]) {
    const localizedFormatter =
      LOCALIZED_DATE_TIME_FORMAT[localizationData.locale][formatter];
    if (localizedFormatter) {
      formatter = localizedFormatter;
    }
  }

  const formatOptions = dateTimeLocale
    ? {
        locale: dateTimeLocale
      }
    : {};

  if (utc) {
    return formatInTimeZone(time, 'UTC', formatter, formatOptions);
  }
  return format(new Date(time), formatter, formatOptions);
};
