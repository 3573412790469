import { cloneElement, useEffect, useState } from 'react';

import GeneratedIconNames from './GeneratedIconNames';

export type IconPath = keyof typeof GeneratedIconNames;

export type IconName<P extends IconPath = 'common'> =
  (typeof GeneratedIconNames)[P][number];

export type IconNameWithPath<P extends IconPath = 'common' | 'social'> = {
  path?: P;
  name: IconName<P>;
};

export type IconType<P extends IconPath = 'common'> = {
  width?: number | string;
  height?: number | string;
  fill?: string;
  fillOpacity?: number | string;
  className?: string;
} & IconNameWithPath<P>;

export const checkValidIcon = (path: string, name: string): IconPath => {
  return GeneratedIconNames[path].includes(name);
};

const loadedIcons = {};

const Icon = <P extends IconPath = 'common'>({
  name,
  width = 24,
  height = 24,
  fill = '#1B1B18',
  fillOpacity = 1,
  path,
  className = ''
}: React.PropsWithChildren<IconType<P>>): React.ReactNode => {
  const fullPath = `./svgs/${path ? path : 'common'}/${name}.svg`;
  const [importedIcon, setImportedIcon] = useState(loadedIcons[fullPath]);
  const [loading, setLoading] = useState(!loadedIcons[fullPath]);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const { default: iconComponent } = await import(`${fullPath}`);

        loadedIcons[fullPath] = iconComponent;
        setImportedIcon(iconComponent);
      } catch (e) {
        console.error(`Unable to load the icon ${name}`, e);
      } finally {
        setLoading(false);
      }
    };
    if (!loadedIcons[fullPath]) {
      importIcon();
    }
  }, [fullPath, name, path]);

  if (!loading && importedIcon) {
    return cloneElement(importedIcon, {
      ...importedIcon.props,
      key: name,
      width,
      height,
      fill,
      fillOpacity,
      className,
      style: {
        minWidth: width + 'px',
        minHeight: height + 'px'
      }
    });
  }

  //preserve the space for the loading icon
  return (
    <i
      style={{
        width: width + 'px',
        height: height + 'px'
      }}
      className={className}
    />
  );
};

export default Icon;
