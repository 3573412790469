import { useRouter } from 'next/router';

function useShallowRouterReplace() {
  const router = useRouter();
  const replaceRoute = (updatedQuery: object, scroll: boolean = true) => {
    router.replace(
      {
        query: { ...updatedQuery }
      },
      undefined,
      {
        shallow: true,
        scroll
      }
    );
  };

  return { replaceRoute };
}

export default useShallowRouterReplace;
