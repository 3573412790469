import config from '@/utility/config';
import { getAxiosError } from '@/services/helpers';
import adminProtectedAxiosRequest from '@/services/helpers/adminProtectedAxiosRequest';
import publicAxiosRequest from '@/services/helpers/publicAxiosRequest';
const { communityApiBasePath, loginApiRoute } = config;

/**
 * Payload should send either authToken or refreshToken
 * @param {*} payload | Object
 * @param {*} payload.authToken | String | For Social Logins
 * @param {*} payload.refreshToken | String
 * @returns Promise
 */
export const getTokensService = async (payload) => {
  try {
    const path = `${loginApiRoute}/api/v1/get-token`;
    return await publicAxiosRequest.post(path, payload, {
      withCredentials: true
    });
  } catch (e) {
    return { error: e.message };
  }
};

export const getAdminProfile = async ({ accessToken }) => {
  try {
    //we don't need to make an unauthorized request unless `accessToken` is provided
    if (!accessToken) {
      return {
        error: 'No access token provided'
      };
    }

    return await adminProtectedAxiosRequest.get(
      `${communityApiBasePath}/api/v1/user-profile`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
  } catch (e) {
    return getAxiosError(e);
  }
};
