import classNames from 'classnames';
import { NPLIconButtonSizeType } from '../NPLIconButton/types';
import { NPLButtonSizeType } from './types';

export const getButtonSize = (
  size: NPLButtonSizeType,
  isPlain: boolean
): string => {
  const buttonHeights: Record<NPLButtonSizeType, string> = {
    xs: 'h-28',
    sm: 'h-32',
    md: 'h-40',
    lg: 'h-48',
    xl: 'h-56'
  };

  if (isPlain) {
    return classNames('px-4', buttonHeights[size]);
  }

  const buttonPaddings: Record<NPLButtonSizeType, string> = {
    xs: 'px-12',
    sm: 'px-12',
    md: 'px-12',
    lg: 'px-16',
    xl: 'px-16'
  };

  return classNames(buttonPaddings[size], buttonHeights[size]);
};

export const getIconSizeByButtonSize = (
  size: NPLIconButtonSizeType
): number => {
  switch (size) {
    case 'xs':
      return 14;
    case 'sm':
      return 16;
    case 'md':
    case 'lg':
    case 'xl':
      return 20;
  }
};

export const getTextStyleByButtonSize = (size: NPLButtonSizeType) => {
  switch (size) {
    case 'xs':
      return 'px-4 text-button-sm font-medium';
    case 'sm':
      return 'px-4 text-button-md font-medium';
    case 'md':
      return 'px-4 text-button-md font-medium';
    case 'lg':
      return 'px-8 text-button-lg font-medium';
    case 'xl':
      return 'px-8 text-button-lg font-medium';
  }
};
