import sessionStorageService from './sessionStorageService';

let sentryModule = null;
let sentryBrowserModule = null;

export const getSentryModule = async () => {
  if (sentryModule) {
    return new Promise((resolve) => {
      resolve(sentryModule);
    });
  }

  return import('@sentry/nextjs').then((module) => {
    sentryModule = module;
    return module;
  });
};

export const getSentryBrowserModule = async () => {
  if (sentryBrowserModule) {
    return new Promise((resolve) => {
      resolve(sentryBrowserModule);
    });
  }

  return import('@sentry/browser').then((module) => {
    sentryBrowserModule = module;
    return module;
  });
};

export const addSentryBreadcrumb = async (message: string) => {
  const sentry = (await getSentryModule()) as {
    addBreadcrumb: (breadcrumb: {
      category: string;
      message: string;
      level: string;
    }) => void;
  };
  typeof sentry.addBreadcrumb === 'function' &&
    sentry.addBreadcrumb({
      category: 'ui.click',
      message: message,
      level: 'info'
    });
};

export const captureSentryError = async (
  error: string,

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moreDetails?: any
) => {
  const user = sessionStorageService.getItem('userData');

  const sentry = (await getSentryBrowserModule()) as {
    captureException: (error: string) => void;
    setUser: (user: { email: string }) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setExtra: (key: string, value: any) => void;
  };
  if (user?.email) {
    sentry.setUser({ email: user.email });
  }
  if (moreDetails) {
    sentry.setExtra('data', moreDetails);
  }

  sentry.captureException(error);
};
