export const AI_SUMMARY_STATES = {
  LOADING: 'LOADING',
  NO_MESSAGE: 'NO_MESSAGE',
  INSUFFICIENT_MESSAGE: 'INSUFFICIENT_MESSAGE',
  PAST_SUMMARY_EXISTS: 'PAST_SUMMARY_EXISTS',
  AVAILABLE: 'AVAILABLE',
  NOT_CONNECTED: 'NOT_CONNECTED',
  NO_CHAT_CONNECTED: 'NO_CHAT_CONNECTED'
};

export const AI_SUMMARY_CONTENT_TYPES = {
  PARAGRAPH: 'paragraph',
  TOPIC: 'topic',
  NOTE: 'note'
};
