import { t } from '@/utility/localization';

export const getDataFromAxiosRes = (res) => {
  const data = res?.data;
  // Check Res Status for Error.
  if (res?.status >= 500) {
    const msg = data?.message || 'Internal Server Error';
    throw new Error(msg);
  }
  if (res?.status >= 400) {
    const msg = data?.info || data?.message || 'Not Found.';
    throw new Error(msg);
  }
  return data;
};

export const getDataFromRes = async (res) => {
  const data = await res.json();

  // Check Res Status for Error.
  if (res.status >= 500) {
    const msg = data?.message || 'Internal Server Error';
    throw new Error(msg);
  }

  if (res.status >= 400) {
    const msg = data?.info || data?.message || 'Not Found.';
    throw new Error(msg);
  }

  return data;
};

export const findErrorMessage = (obj) => {
  if (obj && !Object.keys(obj).length) {
    return obj;
  }

  // check if the current object has a 'message' attribute
  if (obj.message) {
    return obj.message;
  }

  // loop through all the properties of the object
  for (let prop in obj) {
    // check if the property is an object
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      // recursively check the object for an error message
      const message = findErrorMessage(obj[prop]);
      // if an error message was found, return it
      if (message) {
        return message;
      }
    }
  }

  // if no error message was found, return the original message
  return obj;
};

export const getAxiosError = (errorResponse) => {
  const localeErrorMessageKey =
    errorResponse?.response?.data?.localizationDetails?.errorKey ||
    errorResponse?.localizationDetails?.errorKey ||
    errorResponse?.errorObj?.localizationDetails?.errorKey;

  const localeErrorMessage =
    t(localeErrorMessageKey) === localeErrorMessageKey
      ? errorResponse?.response?.data?.localizationDetails
          ?.localizedErrorMessage
      : t(localeErrorMessageKey);

  return {
    error:
      localeErrorMessage ||
      errorResponse?.response?.data?.error?.errorMessage ||
      errorResponse?.response?.data?.error?.message ||
      errorResponse?.response?.data?.message ||
      errorResponse?.response?.data?.errorMessage ||
      errorResponse?.response?.data?.error ||
      errorResponse?.response?.data ||
      errorResponse?.errorMessage ||
      errorResponse?.error ||
      errorResponse?.message ||
      errorResponse ||
      'Something went wrong',
    status: errorResponse?.status
  };
};

export const getAxiosErrorMsg = (errorResponse) => {
  if (typeof errorResponse === 'string') {
    return errorResponse;
  }

  return getAxiosError(errorResponse).error;
};

export const removeQueryParams = (query, paramsToRemove) => {
  const updatedQuery = { ...query };

  // Remove specified parameters
  paramsToRemove.forEach((param) => {
    delete updatedQuery[param];
  });

  return updatedQuery;
};
