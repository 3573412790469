import { t } from '@/utility/localization';

export const TABS_ID = {
  HOME: 'home',
  PRODUCTS: 'products',
  CHALLENGES: 'challenges',
  EVENTS: 'events',
  CHAT: 'chat',
  MEMBERS: 'members',
  ABOUT: 'about'
};

export const TAB_PAGE_VIEW_EVENT_NAME = {
  HOME: 'communityPage_homeTab_pageview',
  PRODUCTS: 'communityPage_productsTab_pageview',
  CHALLENGES: 'communityPage_challengesTab_pageview',
  EVENTS: 'communityPage_eventsTab_pageview',
  CHAT: 'communityPage_chatTab_pageview',
  MEMBERS: 'communityPage_membersTab_pageview',
  ABOUT: 'communityPage_aboutTab_pageview'
};

// I am using hard coded value now for these because the constants are in files
// that contain dynamic imports
export default () => {
  const PRODUCT_FILTER_CHIPS = [
    {
      label: t('all'),
      value: '',
      icon: ''
    },
    {
      label: t('digital-product'),
      value: 'DIGITAL_PRODUCT',
      icon: 'file-01'
    },
    {
      label: t('1-1-session'),
      value: 'SESSION',
      icon: '1-1-session'
    }
  ];

  const EVENT_FILTER_CHIPS = [
    {
      label: t('upcoming'),
      value: 'upcoming',
      icon: ''
    },
    {
      label: t('past'),
      value: 'past',
      icon: ''
    }
  ];
  return { PRODUCT_FILTER_CHIPS, EVENT_FILTER_CHIPS };
};

export const ENTITY_STATUS = {
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing'
};
