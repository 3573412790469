// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f322b5fd4c0eaf0f38f987fd8c5dd0bfcd7213d6"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs';
import sentryBasedConfigs, {
  isSentryConfigAvailable
} from './sentryBasedConfigs';

if (isSentryConfigAvailable) {
  Sentry.init({
    ...sentryBasedConfigs
  });
}
