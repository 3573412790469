export const parseFullName = (fullName) => {
  const nameTokens = fullName.trim().split(' ');
  let firstName = '';
  let lastName = '';
  const tokensLength = nameTokens.length;
  if (tokensLength <= 1) {
    firstName = fullName;
  } else {
    lastName = nameTokens[tokensLength - 1];
    const firstNameTokens = nameTokens.slice(0, tokensLength - 1);
    firstName = firstNameTokens.join(' ');
  }

  return {
    firstName,
    lastName
  };
};

export const convertLocaleStringToNumber = (str) => str.replace(/,/g, '');

//Remove -, (, ) and space
export const sanitisePhoneNumber = (number) => {
  return number
    .replace('-', '')
    ?.replace('(', '')
    ?.replace(')', '')
    .replace(' ', '');
};

export const hashEmail = (email) => {
  if (!email) {
    return '';
  }

  const hashedEmail = email.replace(
    /(\w{2})[\w.-/+]+(\w{2})@([\w.]+\w)/,
    '$1**$2@$3'
  );
  return hashedEmail;
};

export const sanitizeHtml = (htmlString) => {
  let result = htmlString;

  //remove script elements from HTML string
  const regex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  result = result.replace(regex, '');

  //`className` is not applicable in templates, so we reserve `class` to prevent mistakes
  result = result.replaceAll('className', 'class');

  return result;
};

export const getDomainByHostName = (hostName) => {
  if (!hostName || typeof hostName !== 'string') {
    return '';
  }

  // handle localhost
  if (hostName.startsWith('localhost')) {
    return '';
  }

  const parts = hostName.split('.');
  let primaryDomain = parts.slice(-2).join('.');

  //the preview links are dynamic, so we cannot apply any specific domain to cookies
  if (
    primaryDomain === 'vercel.app' ||
    primaryDomain === 'amplifyapp.com'
  ) {
    primaryDomain = '';
  }

  return primaryDomain;
};

export const getPrimaryDomain = () => {
  //server-side rendering does not have window object
  if (typeof window === 'undefined') {
    return '';
  }

  return getDomainByHostName(window.location.hostname);
};

export const readFriendlyPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters from the input phone number
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  const numberLength = cleanedNumber.length;

  const countryCode = cleanedNumber.slice(0, 2);
  let firstPart = '';
  let secondPart = '';

  switch (numberLength) {
    case 10:
      // Format the phone number in a more user-friendly way
      const areaCode = cleanedNumber.slice(2, 4);
      firstPart = cleanedNumber.slice(4, 7);
      secondPart = cleanedNumber.slice(7);

      return `+${countryCode} ${areaCode} ${firstPart} ${secondPart}`;
    case 12:
      // Format the phone number in a more user-friendly way
      firstPart = cleanedNumber.slice(2, 7);
      secondPart = cleanedNumber.slice(7);

      return `+${countryCode} ${firstPart} ${secondPart}`;
    default:
      return cleanedNumber;
  }
};

export const capitalizeFirstLetter = ({ string }) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch {
    return string;
  }
};

export const getFileNameWithExtensionFromLink = (link) => {
  if (!link) {
    return null;
  }
  return link.split('/').pop();
};

export const getFilenameAndExtensionFromLink = (link) => {
  // Get the filename with extension
  const filenameWithExtension = getFileNameWithExtensionFromLink(link);

  // Split the filename to get the filename and extension separately
  const [filename, extension] = filenameWithExtension.split('.');

  return {
    filename: filename,
    extension: extension
  };
};

export const formatFileSize = (filesizeInKb) => {
  if (filesizeInKb < 1024) {
    return filesizeInKb.toFixed(2) + ' KB';
  } else if (filesizeInKb < 1024 * 1024) {
    return (filesizeInKb / 1024).toFixed(2) + ' MB';
  } else {
    return (filesizeInKb / (1024 * 1024)).toFixed(2) + ' GB';
  }
};

export const withoutBackslashPrefix = (str) => {
  return str.replace(/^\/+/g, '');
};

export const ensureHttps = (url) => {
  // Check if the URL starts with 'http://' or 'https://'
  if (url && !/^https?:\/\//i.test(url)) {
    // If not, prepend 'https://'
    url = 'https://' + url;
  }
  return url;
};

export const getCountdownFormattedString = (countdown) => {
  const { years, days, hrs, mins, secs } = countdown || {};

  let str = '';

  if (years > 0) {
    str += `${years}y `;
  }

  if (days > 0 || years > 0) {
    str += `${days}d `;
  }

  if (hrs > 0 || days > 0 || years > 0) {
    str += `${hrs}h `;
  }

  if (mins > 0 || hrs > 0 || days > 0 || years > 0) {
    str += `${mins}m `;
  }

  str += `${secs}s`;

  return str.trim();
};

export const generateMailtoLink = ({ email, subject, body }) => {
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  return `mailto:${email}?subject=${encodedSubject}&body=${encodedBody}`;
};
