import { usePathname, useParams } from 'next/navigation';
import { useEffect, useState } from 'react';

const useParametrizedPathname = (): string => {
  const [parametrizedPathname, setParametrizedPathname] =
    useState<string>('');
  const pathname = usePathname();
  const params = useParams();

  useEffect(() => {
    if (params) {
      let updatedPathname = pathname;
      Object.entries(params).forEach(([key, value]) => {
        const paramValue = Array.isArray(value) ? value[0] : value;
        if (paramValue) {
          updatedPathname = updatedPathname.replace(
            paramValue,
            `[${key}]`
          );
        }
      });

      setParametrizedPathname(updatedPathname);
    }
  }, [pathname, params]);

  return parametrizedPathname;
};

export default useParametrizedPathname;
