import posthog from 'posthog-js';
import config from '@/utility/config';
import sessionStorageService from './sessionStorageService';

const { isBackendCdnEnabled, isPosthogEnabled, isApiMonitoringEnabled } =
  config;

const MAX_CAPTURES_PER_SESSION = 3; // Max number of events to capture per API, per sesson
const API_CAPTURE_COUNTS = 'apiCaptureCount'; // session key

const incrementCaptureCount = (eventName) => {
  // No captures yet this session
  if (!sessionStorageService.getItem(API_CAPTURE_COUNTS)) {
    sessionStorageService.setItem(
      API_CAPTURE_COUNTS,
      JSON.stringify({ [eventName]: 1 })
    );
    return;
  }

  // Increment capture count for this eventName/api
  const captureCounts = JSON.parse(
    sessionStorageService.getItem(API_CAPTURE_COUNTS)
  );

  // This eventName has not been captured
  if (!captureCounts?.[eventName]) {
    captureCounts[eventName] = 1;
  } else {
    // eventName has been captured before
    captureCounts[eventName]++;
  }
  sessionStorageService.setItem(
    API_CAPTURE_COUNTS,
    JSON.stringify(captureCounts)
  );
};

const isReachedMaxCaptureCount = (eventName) => {
  if (!sessionStorageService.getItem(API_CAPTURE_COUNTS)) {
    return false;
  }
  const captureCounts = JSON.parse(
    sessionStorageService.getItem(API_CAPTURE_COUNTS)
  );

  return captureCounts?.[eventName] >= MAX_CAPTURES_PER_SESSION;
};

export const timeRequest = async (req, eventName) => {
  if (typeof window === 'undefined') {
    return await req();
  }

  const start = window.performance.now();
  const res = await req();
  const end = window.performance.now();

  if (
    isPosthogEnabled &&
    isApiMonitoringEnabled &&
    !isReachedMaxCaptureCount(eventName)
  ) {
    posthog?.capture(eventName, {
      duration: end - start,
      withCdn: isBackendCdnEnabled,
      $timestamp: new Date().toISOString()
    });
    incrementCaptureCount(eventName);
  }
  return res;
};
