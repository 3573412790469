import { getLocaleFromCookie } from '@/utility/localization';
import { getAxiosErrorMsg } from '.';

const unAuthorizedRequest = async (path, options) => {
  try {
    const locale = getLocaleFromCookie();

    if (locale && options?.headers) {
      options.headers['Accept-Language'] = locale;
    }

    const res = await fetch(path, options);

    let data = null;
    try {
      data = await res.json();
    } catch (e) {
      console.error({
        status: res.status,
        fullResponse: await res.text()
      }); //log the response for debugging
      return { error: getAxiosErrorMsg(e), data: {} };
    }

    // Check Res Status for Error.
    if (res.status >= 500) {
      const msg =
        data?.message || data?.errorMessage || 'Internal Server Error';
      throw new Error(msg);
    }

    if (res.status >= 400) {
      const msg =
        data?.info || data?.message || data?.errorMessage || 'Not Found.';
      throw new Error(msg);
    }

    return { error: false, data };
  } catch (e) {
    return { error: getAxiosErrorMsg(e), data: {} };
  }
};

export default unAuthorizedRequest;
