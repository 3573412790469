export const convertObjectToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    // Try decoding to see if it's a valid encoded string
    try {
      const decodedValue = decodeURIComponent(obj[key]);
      // If can decode it and it's different from the original,
      // means that it was encoded, so we will just use the original value
      if (decodedValue !== obj[key]) {
        keyValuePairs.push(encodeURIComponent(key) + '=' + obj[key]);
        continue;
      }
    } catch (e) {
      // If decoding fails, treat as unencoded
    }

    // If It get here, treat as unencoded value, so need to encode it
    keyValuePairs.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    );
  }
  return keyValuePairs.join('&');
};

export const convertQueryStringToObject = (queryString) => {
  const params = new URLSearchParams(queryString);
  return Object.fromEntries(params);
};
