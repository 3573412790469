import { ButtonStyles } from './types';

export const buttonStyles: ButtonStyles = {
  accent_primary: {
    light: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'bg-npl-yellow-light-alpha-9 hover:bg-npl-yellow-light-alpha-10 active:bg-[#EBAE11F0]'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-light text-npl-text-disabled-on-light',
        background: 'bg-npl-yellow-light-alpha-3'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'bg-npl-yellow-dark-alpha-9 hover:bg-npl-yellow-light-alpha-10 active:bg-[#EBAE11F0]'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: 'bg-npl-yellow-dark-alpha-3'
      }
    }
  },
  neutral_primary: {
    light: {
      enabled: {
        content:
          'fill-npl-text-primary-on-dark text-npl-text-primary-on-dark',
        background:
          'bg-npl-neutral-dark-solid-3 hover:bg-npl-neutral-dark-solid-4 active:bg-npl-neutral-dark-solid-7'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-light text-npl-text-disabled-on-light',
        background: 'bg-npl-neutral-light-solid-3'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'bg-npl-neutral-light-solid-1 hover:bg-npl-neutral-light-solid-4 active:bg-npl-neutral-light-solid-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: 'bg-npl-neutral-dark-alpha-3'
      }
    }
  },
  accent_secondary: {
    light: {
      enabled: {
        content:
          'fill-npl-yellow-light-alpha-11 text-npl-yellow-light-alpha-11',
        background:
          'bg-npl-yellow-light-alpha-3 hover:bg-npl-yellow-light-alpha-4 active:bg-npl-yellow-light-alpha-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-light text-npl-text-disabled-on-light',
        background: 'bg-npl-yellow-light-alpha-3'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-yellow-dark-alpha-11 text-npl-yellow-dark-alpha-11',
        background:
          'bg-npl-yellow-dark-alpha-3 hover:bg-npl-yellow-dark-alpha-4 active:bg-npl-yellow-dark-alpha-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: 'bg-npl-yellow-dark-alpha-3'
      }
    }
  },
  neutral_secondary: {
    light: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'bg-npl-neutral-light-alpha-3 hover:bg-npl-neutral-light-alpha-4 active:bg-npl-neutral-light-alpha-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-light text-npl-text-disabled-on-light',
        background: 'bg-npl-neutral-light-alpha-3'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-primary-on-dark text-npl-text-primary-on-dark',
        background:
          'bg-npl-neutral-dark-alpha-3 hover:bg-npl-neutral-dark-alpha-4 active:bg-npl-neutral-dark-alpha-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: 'bg-npl-neutral-dark-alpha-3'
      }
    }
  },
  outline: {
    light: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'outline outline-1 outline-npl-neutral-light-alpha-8 hover:bg-npl-neutral-light-alpha-2 active:bg-npl-neutral-light-alpha-3 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-neutral-light-alpha-7 text-npl-neutral-light-alpha-7',
        background:
          'outline outline-1 outline-npl-neutral-light-alpha-7 shadow-npl-styles-button-shadow'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-primary-on-dark text-npl-text-primary-on-dark',
        background:
          'outline outline-1 outline-npl-neutral-dark-alpha-8 hover:bg-npl-neutral-dark-alpha-2 active:bg-npl-neutral-dark-alpha-3 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: 'outline outline-1 outline-npl-neutral-dark-alpha-7'
      }
    }
  },
  plain: {
    light: {
      enabled: {
        content:
          'fill-npl-text-primary-on-light text-npl-text-primary-on-light',
        background:
          'hover:bg-npl-neutral-light-alpha-3 active:bg-npl-neutral-light-alpha-4'
      },
      disabled: {
        content:
          'fill-npl-neutral-light-solid-8 text-npl-neutral-light-solid-8',
        background: ''
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-primary-on-dark text-npl-text-primary-on-dark',
        background:
          'hover:bg-npl-neutral-dark-alpha-4 active:bg-npl-neutral-dark-alpha-5'
      },
      disabled: {
        content:
          'fill-npl-text-disabled-on-dark text-npl-text-disabled-on-dark',
        background: ''
      }
    }
  },
  destructive: {
    light: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'bg-npl-error-light-9 hover:bg-npl-error-light-10 active:bg-npl-error-light-11'
      },
      disabled: {
        content: 'fill-npl-error-light-8 text-npl-error-light-8',
        background: 'bg-npl-error-light-6'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'bg-npl-error-dark-9 hover:bg-npl-error-dark-10 active:bg-npl-error-dark-11'
      },
      disabled: {
        content:
          'fill-npl-text-icon-on-dark-tertiary text-npl-text-icon-on-dark-tertiary',
        background: 'bg-npl-error-dark-6'
      }
    }
  },
  destructive_outline: {
    light: {
      enabled: {
        content:
          'fill-npl-text-icon-on-light-surface-primary text-npl-text-icon-on-light-surface-primary',
        background:
          'outline outline-1 outline-npl-error-light-7 hover:outline-npl-error-light-8 active:outline-npl-error-light-8 hover:bg-npl-error-light-3 active:bg-npl-error-light-4 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-neutral-light-solid-8 text-npl-neutral-light-solid-8',
        background:
          'outline outline-1 outline-npl-error-light-6 shadow-npl-styles-button-shadow'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'outline outline-1 outline-npl-error-dark-7 hover:outline-npl-error-dark-8 active:outline-npl-error-dark-8 hover:bg-npl-error-dark-3 active:bg-npl-error-dark-4 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-neutral-dark-solid-8 text-npl-neutral-dark-solid-8',
        background:
          'outline outline-1 outline-npl-error-dark-6 shadow-npl-styles-button-shadow'
      }
    }
  },
  destructive_plain: {
    light: {
      enabled: {
        content: 'fill-npl-error-light-11 text-npl-error-light-11',
        background:
          'hover:bg-npl-neutral-light-alpha-3 active:bg-npl-neutral-light-alpha-4'
      },
      disabled: {
        content: 'fill-npl-error-light-8 text-npl-error-light-8',
        background: ''
      }
    },
    dark: {
      enabled: {
        content: 'fill-npl-error-dark-11 text-npl-error-dark-11',
        background:
          'hover:bg-npl-neutral-dark-alpha-3 active:bg-npl-neutral-dark-alpha-4'
      },
      disabled: {
        content: 'fill-npl-error-dark-8 text-npl-error-dark-8',
        background: ''
      }
    }
  },
  success: {
    light: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'bg-npl-success-light-9 hover:bg-npl-success-light-10 active:bg-npl-success-light-11'
      },
      disabled: {
        content: 'fill-npl-success-light-8 text-npl-success-light-8',
        background: 'bg-npl-success-light-6'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'bg-npl-success-dark-9 hover:bg-npl-success-dark-10 active:bg-npl-success-dark-11'
      },
      disabled: {
        content: 'fill-npl-success-dark-8 text-npl-success-dark-8',
        background: 'bg-npl-success-dark-6'
      }
    }
  },
  success_outline: {
    light: {
      enabled: {
        content:
          'fill-npl-text-icon-on-light-surface-primary text-npl-text-icon-on-light-surface-primary',
        background:
          'outline outline-1 outline-npl-success-light-7 hover:bg-npl-success-light-3 active:bg-npl-success-light-4 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-neutral-light-solid-8 text-npl-neutral-light-solid-8',
        background:
          'outline outline-1 outline-npl-success-light-6 shadow-npl-styles-button-shadow'
      }
    },
    dark: {
      enabled: {
        content:
          'fill-npl-text-icon-on-dark-primary text-npl-text-icon-on-dark-primary',
        background:
          'outline outline-1 outline-npl-success-dark-7 hover:outline-npl-success-dark-8 active:outline-npl-success-dark-8 hover:bg-npl-success-dark-3 active:bg-npl-success-dark-4 shadow-npl-styles-button-shadow'
      },
      disabled: {
        content:
          'fill-npl-neutral-dark-solid-8 text-npl-neutral-dark-solid-8',
        background: 'bg-npl-success-dark-6'
      }
    }
  },
  success_plain: {
    light: {
      enabled: {
        content: 'fill-npl-success-light-11 text-npl-success-light-11',
        background:
          'hover:bg-npl-neutral-light-alpha-3 active:bg-npl-neutral-light-alpha-4'
      },
      disabled: {
        content: 'fill-npl-success-light-8 text-npl-success-light-8',
        background: ''
      }
    },
    dark: {
      enabled: {
        content: 'fill-npl-success-dark-11 text-npl-success-dark-11',
        background:
          'hover:bg-npl-neutral-dark-alpha-3 active:bg-npl-neutral-dark-alpha-4'
      },
      disabled: {
        content: 'fill-npl-success-dark-8 text-npl-success-dark-8',
        background: ''
      }
    }
  }
};
