import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';

import {
  checkIsDesktop,
  checkIsGtEqLg,
  checkIsGtEqSm,
  checkIsTablet
} from '../../utility/domHelpers';

export const WindowWidthContext = createContext({
  isGtEqSm: false,
  isGtEqLg: true,
  isGtEqMd: false,
  isDesktopView: true
});

export const WindowWidthContextProvider = ({ children }) => {
  const [isDesktopView, setIsDesktopView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);
  const [isGtEqSm, setIsGtEqSm] = useState(false);
  const [isGtEqMd, setIsGtEqMd] = useState(false);
  const [isGtEqLg, setIsGtEqLg] = useState(false);
  const [isGtEqXl, setIsGtEqXl] = useState(false);
  const [isGtEqXxl, setIsGtEqXxl] = useState(false);
  const [isBetweenLgAndXl, setIsBetweenLgAndXl] = useState(false);
  const [isBetweenXLandXXL, setIsBetweenXlandXXL] = useState(false);

  const updateModeOnWindowChange = () => {
    setIsDesktopView(checkIsDesktop());
    setIsTabletView(checkIsTablet());

    setIsGtEqSm(checkIsGtEqSm());
    setIsGtEqMd(window.innerWidth >= 768);
    setIsGtEqLg(checkIsGtEqLg());
    setIsGtEqXl(window.innerWidth >= 1200);
    setIsGtEqXxl(window.innerWidth >= 1536);
    setIsBetweenLgAndXl(
      window.innerWidth >= 992 && window.innerWidth < 1257
    );
    setIsBetweenXlandXXL(
      window.innerWidth >= 1258 && window.innerWidth < 1450
    );
  };

  const initListener = useCallback(() => {
    window.addEventListener('resize', () => updateModeOnWindowChange());
  }, []);

  const removeListener = useCallback(() => {
    window.removeEventListener('resize', updateModeOnWindowChange);
  }, []);

  useLayoutEffect(() => {
    initListener();

    return removeListener();
  }, [initListener, removeListener]);

  useEffect(() => {
    setIsDesktopView(checkIsDesktop());
    setIsTabletView(checkIsTablet());
    updateModeOnWindowChange();
  }, []);

  const value = {
    isDesktopView,
    isTabletView,
    isGtEqSm,
    isGtEqMd,
    isGtEqLg,
    isGtEqXl,
    isGtEqXxl,
    isBetweenLgAndXl,
    isBetweenXLandXXL
  };

  return (
    <WindowWidthContext.Provider value={value}>
      {children}
    </WindowWidthContext.Provider>
  );
};

export const useWindowWidthContext = () => useContext(WindowWidthContext);
