import { addDays } from 'date-fns';

import CookieService from '@/utility/cookieService';
import localStorageService from '@/utility/localStorageService';
import { parseJSON } from '@/utility/parseJSON';
import sessionStorageService from '@/utility/sessionStorageService';

export const ACCESS_TOKEN_KEY = 'accessTokenNA';
export const REFRESH_TOKEN_KEY = 'refreshTokenNA';
export const USER_LOGGED_IN_KEY_SESSION = 'userLoggedIn';
export const TELEGRAM_TOKEN = 'telegramToken';

const USER_DATA_KEY = 'userData';

class Auth {
  /**
   * Save the accessToken in cookies
   *
   * @param {string} accessToken
   * @param {string} refreshToken
   */
  static authenticateUser(accessToken, refreshToken) {
    CookieService.set(ACCESS_TOKEN_KEY, accessToken, {
      expires: addDays(new Date(), 62)
    });
    CookieService.set(REFRESH_TOKEN_KEY, refreshToken, {
      expires: addDays(new Date(), 125)
    });
  }

  /**
   * De-authenticate a user.
   * Remove a token from Local Storage.
   *
   */
  static deauthenticateUser(redirect = true, callback) {
    localStorageService.removeItem(TELEGRAM_TOKEN);
    sessionStorageService.removeItem(USER_DATA_KEY);
    sessionStorageService.removeItem(USER_LOGGED_IN_KEY_SESSION);
    CookieService.delete(ACCESS_TOKEN_KEY);
    CookieService.delete(REFRESH_TOKEN_KEY);

    if (redirect && callback) {
      callback();
    }
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserToken() {
    return CookieService.get(ACCESS_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return CookieService.get(REFRESH_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getUserTokenFromCookies() {
    return CookieService.get(ACCESS_TOKEN_KEY);
  }

  /**
   * return user token
   *
   * @returns {string}
   */
  static getRefreshTokenFromCookies() {
    return CookieService.get(REFRESH_TOKEN_KEY);
  }

  static isAuthenticated() {
    return !!this.getUserToken();
  }

  static setUserDataSession(userData) {
    sessionStorageService?.setItem(
      USER_DATA_KEY,
      JSON.stringify(userData)
    );
  }

  static getUserDataSession() {
    const userData = sessionStorageService?.getItem(USER_DATA_KEY);
    return userData ? parseJSON(userData) : undefined;
  }

  static getUserIdFromSession() {
    const userData = this.getUserDataSession();
    return userData?.learner?._id;
  }

  static getUserEmailFromSession() {
    const userData = this.getUserDataSession();
    return userData?.email;
  }

  //use this for tracking/analytics purposes only
  static setUserLoggedInForSession() {
    sessionStorageService?.setItem(
      USER_LOGGED_IN_KEY_SESSION,
      'logged_in'
    );
  }

  //use this for tracking/analytics purposes only
  static getUserLoggedInForSession() {
    const isUserLoggedIn = sessionStorageService?.getItem(
      USER_LOGGED_IN_KEY_SESSION
    );

    return !!isUserLoggedIn;
  }
}

export default Auth;
