import { captureException } from '@sentry/nextjs';
import { add, format, isFuture } from 'date-fns';
import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { DateTime } from 'luxon';

import { formatDateTimeWithLocale, t } from '@/utility/localization';

// This is for rare cases where the user is using a machine that returns this timezone
const etcTimezoneMapping: { [key: string]: string } = {
  'Etc/GMT+12': 'Pacific/Wake',
  'Etc/GMT+11': 'Pacific/Pago_Pago',
  'Etc/GMT+10': 'Pacific/Honolulu',
  'Etc/GMT+9': 'Pacific/Marquesas',
  'Etc/GMT+8': 'Pacific/Pitcairn',
  'Etc/GMT+7': 'America/Boise',
  'Etc/GMT+6': 'America/Chicago',
  'Etc/GMT+5': 'America/New_York',
  'Etc/GMT+4': 'America/Santo_Domingo',
  'Etc/GMT+3': 'America/Argentina/Buenos_Aires',
  'Etc/GMT+2': 'Atlantic/South_Georgia',
  'Etc/GMT+1': 'Africa/Lagos',
  'Etc/GMT+0': 'Africa/Freetown',
  'Etc/GMT-0': 'Africa/Freetown',
  'Etc/GMT-1': 'Atlantic/Azores',
  'Etc/GMT-2': 'Atlantic/South_Georgia',
  'Etc/GMT-3': 'America/Argentina/Buenos_Aires',
  'Etc/GMT-4': 'America/Santo_Domingo',
  'Etc/GMT-5': 'America/New_York',
  'Etc/GMT-6': 'America/Chicago',
  'Etc/GMT-7': 'America/Boise',
  'Etc/GMT-8': 'Pacific/Pitcairn',
  'Etc/GMT-9': 'Pacific/Marquesas',
  'Etc/GMT-10': 'Pacific/Honolulu',
  'Etc/GMT-11': 'Pacific/Pago_Pago',
  'Etc/GMT-12': 'Pacific/Wake'
};

export const formatDate = (date: Date | string | null): Date => {
  if (!date) {
    return new Date();
  }
  return typeof date === 'string' ? new Date(date) : date;
};

export function timeSince(date: Date): string {
  const seconds = Math.floor(
    (new Date().getTime() - date.getTime()) / 1000
  );

  let interval = seconds / 31536000;

  if (interval <= 0) return t('just-now');

  if (interval > 1) {
    return Math.floor(interval) + t('timesince-year');
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + t('timesince-month');
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (interval > 7) {
      const inWeeks = interval / 7;
      return Math.floor(inWeeks) + t('timesince-week');
    }

    return Math.floor(interval) + t('timesince-day');
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + t('timesince-hour');
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + t('timesince-min');
  }
  return t('just-now');
}

export const getGMTOffset = (): string => {
  return formatDateTimeWithLocale(new Date(), 'O');
};

interface FormattedStartEndDateParams {
  endDate: Date | string;
  startDate: Date | string;
  hasGMT?: boolean;
  hasTime?: boolean;
  isUTC?: boolean;
}

export const getFormattedStartEndDate = ({
  startDate,
  endDate,
  hasTime = false,
  hasGMT = false,
  isUTC = false
}: FormattedStartEndDateParams): string => {
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();

  const isInSameYear = startYear === endYear;

  let startDateFormat = isInSameYear ? 'dd MMM' : 'dd MMM yyyy';
  let endTimeFormat = 'dd MMM yyyy';

  if (hasTime) {
    startDateFormat += ', hh:mm aaa';
    endTimeFormat += ', hh:mm aaa';
  }

  const formattedItems: string[] = [];

  formattedItems.push(
    formatDateTimeWithLocale(startDate, startDateFormat, isUTC)
  );

  if (endDate) {
    formattedItems.push('-');
    formattedItems.push(
      formatDateTimeWithLocale(endDate, endTimeFormat, isUTC)
    );
  }

  if (hasGMT) {
    formattedItems.push(getGMTOffset());
  }

  return formattedItems.join(' ');
};

// 05:30 AM
export const getHourAndMinuteByLocale = ({
  date
}: {
  date: Date | string;
}): string => {
  return formatDateTimeWithLocale(date, 'hh:mm aaa');
};

// 05:30 AM - 06:30 AM
export const getHourRangeByLocale = ({
  startDate,
  endDate
}: {
  endDate: Date | string;
  startDate: Date | string;
}): string => {
  return `${getHourAndMinuteByLocale({
    date: startDate
  })} - ${getHourAndMinuteByLocale({
    date: endDate
  })}`;
};

export const getTimezoneId = (): string => {
  try {
    const dateTimeFormat = new Intl.DateTimeFormat();
    let timezone =
      dateTimeFormat.resolvedOptions().timeZone ??
      DateTime.local().zoneName;

    // Override Etc/GMT zones with specific IANA zones
    if (timezone.startsWith('Etc/GMT')) {
      timezone = etcTimezoneMapping[timezone] || timezone;
    }

    return timezone;
  } catch {
    // A fallback solution if Intl is not supported
    return DateTime.local().zoneName;
  }
};

export const getGMTOffsetFromTimezoneId = (timezoneId: string): string => {
  try {
    const formattedOffset = formatInTimeZone(new Date(), timezoneId, 'O');
    return formattedOffset;
  } catch (error) {
    // This happens when a browser version is old, this timezone is added only last year
    // hence a lot of browser do not support this timezoneId
    if (error.message.includes('America/Ciudad_Juarez')) {
      return formatInTimeZone(new Date(), 'America/Mexico_City', 'O');
    } else {
      // Log error in sentry and show error toast
      captureException(error);
    }
  }
};

// TODO write test for this.
export const getLocalCurrentAndRecurringMonthlyDeadline = (
  day: number
) => {
  const currentDate = new Date();
  const localDay = parseInt(format(currentDate, 'd'));

  const targetDate =
    localDay < day ? currentDate : add(currentDate, { months: 1 });

  const localMonth = parseInt(format(targetDate, 'L')) - 1;
  const localYear = parseInt(format(targetDate, 'Y'));

  const localDeadline = new Date(localYear, localMonth, day);
  const localDeadlineString = formatDateTimeWithLocale(
    localDeadline,
    'eee, dd MMM yyyy'
  );
  const currentLocalDateString = formatDateTimeWithLocale(
    currentDate,
    'eee, dd MMM yyyy'
  );

  const isDeadlineDay = localDay === day;

  return {
    currentLocalDateString,
    localDay,
    localDeadline,
    localDeadlineString,
    isDeadlineDay
  };
};

/*
returning date in format similar to 22 Feb 2022
*/

export const getShortMonthDate = (date: string | Date = ''): string => {
  try {
    if (!date) return '';
    return formatDateTimeWithLocale(new Date(date), 'dd MMM yyyy');
  } catch (err) {
    return '';
  }
};

/**
 * Check if given dateB is "numDays" days away from dateA.
 * @param {Date} dateA
 * @param {Date} dateB
 * @param {number} numDays Number of Days to add to Today.
 * @return {boolean} True is given date is numDays away from Today
 */
export const isDateNDaysBeyond = (
  dateA: Date,
  dateB: Date,
  numDays: number
): boolean => {
  try {
    const currDate = new Date(dateA);
    const targetDate = new Date(dateB);
    const nDaysLaterDate = add(currDate, { days: numDays });

    return targetDate > nDaysLaterDate;
  } catch (e) {
    return false;
  }
};

interface DateItemsObject {
  date: number;
  day: string;
  month: string;
  time: string;
}

export const getDateItemsObject = (
  dateInput: Date | string
): DateItemsObject => {
  const formattedDate = formatDate(dateInput);
  const month = formatDateTimeWithLocale(formattedDate, 'MMM');
  const date = formattedDate.getDate();

  // get time from date
  const time = formatDateTimeWithLocale(formattedDate, 'hh:mm aaa');

  let day = formatDateTimeWithLocale(formattedDate, 'eee');

  const presentDate = new Date();

  const presentDateDay = presentDate.getDate();
  const isCurrentMonth =
    presentDate.getMonth() === formattedDate.getMonth();
  const isCurrentYear =
    presentDate.getFullYear() === formattedDate.getFullYear();

  const isActivePeriod = isCurrentMonth && isCurrentYear;
  day =
    presentDateDay === date && isActivePeriod
      ? t('today')
      : presentDateDay - date === 1 && isActivePeriod
        ? t('yesterday')
        : day;

  return {
    month,
    date,
    day,
    time
  };
};

export function formatSecondsToBrokenDownTime(
  totalSeconds: number
): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  let formattedDuration = '';
  formattedDuration = hours > 0 ? Math.trunc(hours) + 'h ' : '';
  formattedDuration += minutes > 0 ? Math.trunc(minutes) + 'm ' : '';
  formattedDuration += seconds > 0 ? Math.trunc(seconds) + 's' : '';

  return formattedDuration;
}

// return date X days From now
export const getDateXDaysFromNow = (
  date: Date,
  days: number = 1
): string => {
  const nextDate = add(date, { days });
  return getShortMonthDate(nextDate);
};

// return same date next month in format 31 July 2023
export const getSameDayinXMonths = (
  date: Date,
  months: number = 1
): string => {
  const nextMonth = add(date, { months });
  return getShortMonthDate(nextMonth);
};

// return same date next month in format 31 July 2023
export const getStartTimeWithoutTimeZone = (date: Date): string => {
  const startDate = new Date(date);

  return `${startDate?.getFullYear()}-${String(
    startDate?.getMonth() + 1
  ).padStart(2, '0')}-${String(startDate?.getDate()).padStart(
    2,
    '0'
  )}T00:00:00.000Z`;
};

// return same date next month in format 31 July 2023
export const getEndTimeWithoutTimeZone = (date: Date): string => {
  const endDate = new Date(date);

  return `${endDate?.getFullYear()}-${String(
    endDate?.getMonth() + 1
  ).padStart(2, '0')}-${String(endDate?.getDate()).padStart(
    2,
    '0'
  )}T23:59:59.999Z`;
};

export const getDateAndTime = (date: Date | string): string => {
  date = formatDate(date);

  const data = getDateItemsObject(date);

  if (data.day === t('yesterday') || data.day === t('today')) {
    return `${data.day}, ${String(data.time)}`;
  } else {
    const formattedDateTime = formatDateTimeWithLocale(
      date,
      'dd MMM yyyy, hh:mm aaa'
    );

    return formattedDateTime;
  }
};

export const getMinutesFromStartOfDay = (timeString: string): number => {
  // Check if the timeString is in the correct format "HH:mm"
  if (!/^\d{2}:\d{2}$/.test(timeString)) {
    return 0;
  }
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Calculate the total minutes from the start of the day
  const minutesFromStartOfDay = hours * 60 + minutes;

  return minutesFromStartOfDay;
};

// get minutes from start of the day
export const getMinutesFromStartOfDayForDate = (
  date: Date | string
): number => {
  const localdate = new Date(date);
  const hours = localdate.getHours();
  const minutes = localdate.getMinutes();
  return hours * 60 + minutes;
};

export const getTimeAgoString = (date: Date | string): string => {
  const dateFormat = new Date(date);
  const timeSinceString = timeSince(dateFormat);
  return timeSinceString === t('just-now')
    ? timeSinceString
    : t('ago', { time: timeSinceString });
};

export const isTimeInBetweenTwoTimes = (
  date1: Date | string,
  date2: Date | string,
  minus_minutes_on_start_time: number
): boolean | null => {
  try {
    const minus_minutes_on_start_time_in_milliseconds =
      minus_minutes_on_start_time * 60 * 1000;
    const formattedDate1 = new Date(date1).getTime();
    const formattedDate2 = new Date(date2).getTime();
    const presentDate = new Date().getTime();
    const formattedDate1_minus_minutes_on_start_time =
      formattedDate1 - minus_minutes_on_start_time_in_milliseconds;

    return (
      formattedDate1_minus_minutes_on_start_time < presentDate &&
      formattedDate2 > presentDate
    );
  } catch (err) {
    return null;
  }
};

export const getNextBillingDateFromDate = (
  date: Date | string | null,
  statusOfSubscription: string,
  cancelledAt: Date | string | null
): string | null => {
  if (!date) return null;

  // handling all cancelled states
  if (statusOfSubscription === 'Cancelled') {
    if (!cancelledAt || !isFuture(new Date(cancelledAt))) {
      return null;
    }
    return new Date(cancelledAt).toISOString();
  } else {
    // Handling current State
    const result = date ? formatDate(date) : new Date();

    if (isFuture(result)) {
      // This is the correct date already
      return result.toISOString();
    } else {
      result.setMonth(result.getMonth() + 1);
      return result.toISOString();
    }
  }
};

export const getPlainTime = (date: Date | string): string | null => {
  try {
    // am aaa
    // hour hh
    // minute mm
    return formatDateTimeWithLocale(formatDate(date), 'hh:mm aaa');
  } catch (err) {
    return null;
  }
};

export const getLongTimeZoneName = (
  date: Date | string
): string | null => {
  try {
    const timezoneWithDate = new Date(date)
      .toLocaleDateString(undefined, { timeZoneName: 'long' })
      .split(',');

    return timezoneWithDate[1].trim();
  } catch (err) {
    return null;
  }
};

// get yesterday's date (mainly for AI summary)
export const getYesterdayDate = (): Date => {
  const oneDayBefore = new Date();
  oneDayBefore.setDate(oneDayBefore.getDate() - 1);
  return oneDayBefore;
};

// get start of the month's date
export const getStartOfMonthDate = (): string => {
  const currentDate = new Date();

  // Get year, month, and day in UTC
  const year = currentDate.getUTCFullYear();
  const month = currentDate.getUTCMonth();

  // Create a new Date object in UTC for the first day of the current month
  const startOfMonthUTC = new Date(Date.UTC(year, month, 1, 0, 0, 0));

  return startOfMonthUTC.toISOString();
};

// for AIChatSummaryInfo
export const formatDateParam = (dateParam: Date | string | null): Date => {
  try {
    if (!dateParam) {
      return new Date();
    }

    const date = new Date(dateParam);
    return date;
  } catch {
    return new Date();
  }
};

export const getDateTimeWithTimezone = (
  date: Date | string,
  timezoneId: string
): Date => {
  return new Date(
    formatInTimeZone(date, timezoneId, 'yyyy-MM-dd HH:mm:ss')
  );
};

export function convertToUTCWithTimezoneId(
  date: Date | string,
  timezoneId: string
): string {
  const utcDate = fromZonedTime(date, timezoneId);

  // Convert to UTC and get the ISO string
  return utcDate.toISOString();
}

// Extracts the date part in YYYY-MM-DD format
export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns a zero-based index
  const day = date.getDate();

  // Pad single digit months and days with a leading zero
  const formattedMonth = month.toString().padStart(2, '0');
  const formattedDay = day.toString().padStart(2, '0');

  return `${year}-${formattedMonth}-${formattedDay}`;
};

// Extracts the time part in HH:mm format
export const formatTimeToHHmm = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad single digit hours and minutes with a leading zero
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export function getDatesInRange(
  startDate: Date,
  endDate: Date | null
): string[] {
  const dateArray: string[] = [];

  if (!endDate) {
    return [formatDateTimeWithLocale(startDate, 'dd/MM/yyyy')];
  }
  const start = startDate;
  const end = endDate;

  const currentDate = start;

  while (currentDate <= end) {
    dateArray.push(formatDateTimeWithLocale(currentDate, 'dd/MM/yyyy'));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

export const isValidDate = (dateString: string): boolean => {
  return !isNaN(Date.parse(dateString));
};

export const convertTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const calculateChallengeDuration = (
  startTime: number,
  endTime: number
) => {
  const duration = Math.abs(endTime - startTime);
  return Math.floor(duration / (1000 * 60 * 60 * 24));
};

export const getChallengeDurationText = (
  startTime: string | Date,
  endTime: string | Date
) => {
  const duration = calculateChallengeDuration(
    new Date(startTime).getTime(),
    new Date(endTime).getTime()
  );
  const isWeek = duration % 7 === 0;
  const isMonth = duration % 30 === 0;

  if (isWeek) {
    return `${duration / 7} ${
      duration / 7 === 1 ? t('week') : t('weeks')
    }`;
  } else if (isMonth) {
    return `${duration / 30} ${
      duration / 30 === 1 ? t('month') : t('months')
    }`;
  } else {
    return `${duration} ${duration === 1 ? t('day') : t('days')}`;
  }
};
