import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import {
  getBrowserLocale,
  getLocaleFromCookie,
  importDateTimeFormatWithLocale
} from '@/utility/localization';

import { useAuthContext } from '@/contexts/AuthContext';

const useLocale = () => {
  const router = useRouter();
  const { user } = useAuthContext();
  const reactDatePickerRef = useRef();
  const currentLocale =
    user?.learner?.languagePreference ||
    getLocaleFromCookie() ||
    getBrowserLocale() ||
    router.locale;

  //register locale for React's date picker
  useEffect(() => {
    const populateDateTimeFormat = async () => {
      const dateTimeFormat = importDateTimeFormatWithLocale(currentLocale);
      if (!reactDatePickerRef.current) {
        reactDatePickerRef.current = await import('react-datepicker');
      }
      reactDatePickerRef.current.registerLocale(
        currentLocale,
        dateTimeFormat
      );
    };
    populateDateTimeFormat();
  }, [currentLocale]);

  return {
    currentLocale
  };
};

export default useLocale;
