export const COMMUNITY_SIGNUP_FORM_STATE = {
  EMAIL_WITH_SOCIAL_LOGIN: 'EMAIL_WITH_SOCIAL_LOGIN',
  OTP: 'OTP',
  LOGIN_WITH_PASSWORD: 'LOGIN_WITH_PASSWORD'
};

export const CHECKOUT_ORIGIN_TYPE = {
  EVENT: 'event',
  FOLDER: 'folder',
  CHALLENGE: 'challenge',
  POST: 'post',
  ONE_ON_ONE_SESSION: 'session'
};
