const config = {
  apiPaymentBasePath: process.env.NEXT_PUBLIC_BACKEND_PAYMENT_URL,
  apiNewPaymentBasePath: process.env.NEXT_PUBLIC_NEW_BACKEND_PAYMENT_URL,
  apiStaticDataPath: process.env.NEXT_PUBLIC_BACKEND_STATIC_DATA_SEVER,
  apiStaticDataAuthToken:
    process.env.NEXT_PUBLIC_BACKEND_STATIC_DATA_AUTH_TOKEN,
  analyticsApiBasePath: process.env.NEXT_PUBLIC_ANALYTICS_URL,
  communityApiBasePath: process.env.NEXT_PUBLIC_COMMUNITY_PORTAL_BACKEND,
  communityApiBasePathCdn:
    process.env.NEXT_PUBLIC_COMMUNITY_PORTAL_BACKEND_CDN,
  stripeApiKey: process.env.NEXT_PUBLIC_STRIPE_KEY,
  stripeIndiaApiKey: process.env.NEXT_PUBLIC_STRIPE_INDIA_KEY,
  backendPaymentAuthKey: process.env.NEXT_PUBLIC_BACKEND_PAYMENT_AUTH,
  memberHideThreshold: process.env.NEXT_PUBLIC_MEMBER_HIDE_THRESHOLD,
  appleClientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
  appleRedirectLink: process.env.NEXT_PUBLIC_APPLE_REDIRECT_LINK,
  gtmId: process.env.NEXT_PUBLIC_GTM,
  imagesBasePath: process.env.NEXT_PUBLIC_CLOUDFRONT_URL,
  memberPortalPath: process.env.NEXT_PUBLIC_LEARNER_PORTAL_PATH,
  appleAppStoreLink: process.env.NEXT_PUBLIC_APPLE_APP_STORE,
  googlePlayStoreLink: process.env.NEXT_PUBLIC_GOOGLE_PLAY_STORE,
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  fbAppId: process.env.NEXT_PUBLIC_FB_APP_ID,
  siteBaseUrl: process.env.NEXT_PUBLIC_SITE_BASE_URL,
  urlShortenerApi: process.env.NEXT_PUBLIC_URL_SHORTENER_API,
  isDevEnvironment: process.env.NEXT_PUBLIC_ENV === 'development',
  isProdEnvironment: process.env.NEXT_PUBLIC_ENV === 'production',
  appleStoreId: process.env.NEXT_PUBLIC_APPLE_STORE_ID,
  nasIoAppDynamicLink: process.env.NEXT_PUBLIC_NAS_IO_APP_DYNAMIC_LINK,
  revalidateSec:
    parseInt(process.env.NEXT_PUBLIC_CONTENTFUL_REVALIDATE_SEC, 10) || 100,
  connectDiscordLink: process.env.NEXT_PUBLIC_CONNECT_DISCORD_CHAT_URL,
  connectTelegramLink: process.env.NEXT_PUBLIC_CONNECT_TELEGRAM_CHAT_URL,
  telegramApiId: process.env.NEXT_PUBLIC_TELEGRAM_API_ID,
  telegramApiHash: process.env.NEXT_PUBLIC_TELEGRAM_API_HASH,
  telegramBotUserName: process.env.NEXT_PUBLIC_TELEGRAM_BOT_USER_NAME,
  telegramBotUserId: process.env.NEXT_PUBLIC_TELEGRAM_BOT_USER_ID,
  telegramBackEndApiKey: process.env.NEXT_PUBLIC_TELEGRAM_API_KEY,
  discordClientId: process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID,
  discordBotPermissionsNumber:
    process.env.NEXT_PUBLIC_DISCORD_BOT_PERMISSIONS_NUMBER,
  socialLoginsOnSignup: process.env.NEXT_PUBLIC_SOCIAL_LOGINS_ON_SIGNUP,
  socialLoginsOnLogin: process.env.NEXT_PUBLIC_SOCIAL_LOGINS_ON_LOGIN,
  whatsappBackEndApiKey: process.env.NEXT_PUBLIC_WHATSAPP_API_KEY,
  whatsappBackendPath: process.env.NEXT_PUBLIC_WHATSAPP_BACKEND,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  intercomApiBase: process.env.NEXT_PUBLIC_INTERCOM_API_BASE,
  loginApiRoute: process.env.NEXT_PUBLIC_LOGIN_ROUTE,
  whatsappDefaultTemplateId:
    process.env.NEXT_PUBLIC_WHATSAPP_DEFAULT_TEMPLATE_ID,
  legendsCommunityId: process.env.NEXT_PUBLIC_LEGENDS_COMMUNITY_ID,
  paypalClientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
  xenditPOCPaymentPath: process.env.NEXT_PUBLIC_TEST_XENDIT_URL,
  whatsappGodModeBePath:
    process.env.NEXT_PUBLIC_WHATSAPP_GOD_BE || 'http://localhost:3001',
  whatsappBotBePath:
    process.env.NEXT_PUBLIC_WHATSAPP_BOT_BE || 'http://localhost:3001',
  payoutThreshold: process.env.NEXT_PUBLIC_PAYOUT_THRESHOLD,
  whatsappBotNumber: process.env.NEXT_PUBLIC_WHATSAPP_BOT_NUMBER,
  fallbackWhatsappBotNumber:
    process.env.NEXT_PUBLIC_FALLBACK_WHATSAPP_BOT_NUMBER,
  whatsappUnavailabilityLink:
    process.env.NEXT_PUBLIC_WHATSAPP_UNAVAILABILITY_LINK,
  whatsappDisabling: process.env.NEXT_PUBLIC_WHATSAPP_DISABLING === 'true',
  isWelcomeMessageAvailable:
    process.env.NEXT_PUBLIC_WELCOME_MESSAGE === 'true',
  earningsTabAllowedIds: process.env.NEXT_PUBLIC_EARNINGS_TAB_ALLOWED_IDS,
  enableEarningsTabWhiteList:
    process.env.NEXT_PUBLIC_ENABLE_EARNINGS_TAB_WHITELIST === 'true',
  enableEarningBeforeSeptemberBlock:
    process.env.NEXT_PUBLIC_ENABLE_EARNINGS_BEFORE_SEPT_BLOCK === 'true',
  payoutWhitelistEnabled:
    process.env.NEXT_PUBLIC_PAYOUT_WHITELIST_ENABLED === 'true',
  payoutWhitelistIDs: process.env.NEXT_PUBLIC_PAYOUT_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_PAYOUT_WHITELIST_IDS.split(',')
    : [],
  localeFilePath: process.env.NEXT_PUBLIC_LOCALE_FILE_PATH,
  firebaseConfig: process.env.NEXT_PUBLIC_FIREBASE_CONFIG
    ? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG)
    : null,
  soldOutResourcePaths: process.env.NEXT_PUBLIC_SOLD_OUT_RESOURCE_PATHS
    ? process.env.NEXT_PUBLIC_SOLD_OUT_RESOURCE_PATHS.split(',')
    : [],
  enablePublicPageImageBannerForCache:
    process.env.NEXT_PUBLIC_CACHE_PUBLIC_PAGE_BANNER_IMAGE === 'true',
  sentryDns: process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN,
  allowCheckoutWidgetForAll:
    process.env.NEXT_PUBLIC_ALLOW_CHKOUT_WIDGET_TO_ALL === 'true',
  whitelistedCommLinkForCheckoutWidget:
    process.env.NEXT_PUBLIC_ALLOW_FOR_CHKOUT_WIDGET,
  razorPayKeyId: process.env.NEXT_PUBLIC_RAZORPAY_KEY_ID,
  razorPayWhitelistEnabled:
    process.env.NEXT_PUBLIC_ENABLE_RAZORPAY_WHITELIST === 'true',
  razorPayWhitelistIDs: process.env.NEXT_PUBLIC_RAZORPAY_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_RAZORPAY_WHITELIST_IDS.split(',')
    : [],
  ebanxWhitelistEnabled:
    process.env.NEXT_PUBLIC_EBANX_WHITELIST_IDS?.length > 0,
  ebanxWhitelistIDs: process.env.NEXT_PUBLIC_EBANX_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_EBANX_WHITELIST_IDS.split(',')
    : [],
  isNewMemberSelectionEnabled:
    process.env.NEXT_PUBLIC_IS_NEW_MEMBER_SELECTION_ENABLED === 'true',
  whiteListHideMemberCount: process.env.NEXT_PUBLIC_HIDE_MEMBER_COUNT
    ? process.env.NEXT_PUBLIC_HIDE_MEMBER_COUNT.split(',')
    : [],
  stripeIndiaWhitelistEnabled:
    process.env.NEXT_PUBLIC_ENABLE_STRIPE_INDIA_WHITELIST === 'true',
  stripeIndiaWhitelistIDs: process.env
    .NEXT_PUBLIC_STRIPE_INDIA_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_STRIPE_INDIA_WHITELIST_IDS.split(',')
    : [],
  ebanxPublicKey: process.env.NEXT_PUBLIC_EBANX_KEY,
  framerWhitelistedPaths:
    process.env.FRAMER_WHITELISTED_PATHS?.split(',') || [],
  razorPayFreeTrialWhitelistEnabled:
    process.env.NEXT_PUBLIC_ENABLE_RAZORPAY_FREETRIAL_WHITELIST === 'true',
  razorPayFreeTrialWhitelistIDs: process.env
    .NEXT_PUBLIC_RAZORPAY_FREETRIAL_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_RAZORPAY_FREETRIAL_WHITELIST_IDS.split(',')
    : [],
  isImageOptimizationEnabled:
    process.env.NEXT_PUBLIC_IMAGE_OPTIMIZATION === 'true',
  googleCalendarClientId:
    process.env.NEXT_PUBLIC_GOOGLE_CALENDAR_CLIENT_ID,
  whitelistedCommIdForPixelLogging: (
    process.env.NEXT_PUBLIC_WHITELISTED_COMMUNITY_IDS_FOR_PIXEL_LOGGING ||
    ''
  ).split(','),
  isPosthogEnabled: process.env.NEXT_PUBLIC_IS_POSTHOG_ENABLED === 'true',
  freshdeskWidgetId: process.env.NEXT_PUBLIC_FRESHDESK_WIDGET_ID,
  isBackendCdnEnabled: process.env.NEXT_PUBLIC_USE_CDN_DOMAIN === 'true',
  isApiMonitoringEnabled:
    process.env.NEXT_PUBLIC_IS_API_MONITORING_ENABLED === 'true',
  revalidateCommunityEntityPage:
    parseInt(process.env.NEXT_PUBLIC_COMMUNITY_ENTITY_REVALIDATE_SEC) ||
    20,
  whitelistToHideFeedCardViewCount: process.env
    .NEXT_PUBLIC_HIDE_FEED_VIEW_COUNT
    ? process.env.NEXT_PUBLIC_HIDE_FEED_VIEW_COUNT.split(',')
    : [],
  whitelistToHideFeedCardLikeCount: process.env
    .NEXT_PUBLIC_HIDE_FEED_LIKE_COUNT
    ? process.env.NEXT_PUBLIC_HIDE_FEED_LIKE_COUNT.split(',')
    : [],
  parentFrameDomain: process.env.NEXT_PUBLIC_PARENT_FRAME_DOMAIN,
  paypalWhitelistEnabled:
    process.env.NEXT_PUBLIC_ENABLE_PAYPAL_WHITELIST === 'true',
  paypalWhitelistIDs: process.env.NEXT_PUBLIC_PAYPAL_WHITELIST_IDS
    ? process.env.NEXT_PUBLIC_PAYPAL_WHITELIST_IDS.split(',')
    : [],
  isChallengeBannerImprovementEnabled:
    process.env.NEXT_PUBLIC_IS_CHALLENGE_BANNER_IMPROVEMENT_ENABLED ===
    'true',
  isFlutterWebEnabled: process.env.NEXT_PUBLIC_ENABLE_FLUTTER_WEB === '1',
  isAlwaysOnChallengeEnabled:
    process.env.NEXT_PUBLIC_IS_ALWAYS_ON_CHALLENGE_ENABLED === 'true',
  analyticsEndpoint: process.env.NEXT_PUBLIC_ANALYTICS_ENDPOINT,
  whitelistedCommCodesForUserBehaviorTracking:
    process.env.NEXT_PUBLIC_USER_BEHAVIOR_TRACKING_COMM_CODES.split(',')
};

exports.config = config;
module.exports = config;
