import classNames from 'classnames';
import React from 'react';

export type WithComponentClassName<P> = P & {
  componentClassName?: string;
};

const withComponentClassName = <P extends object & { className?: string }>(
  WrappedComponent: React.FC<WithComponentClassName<P>>,
  isElementWrapped = true
) => {
  const WrapperComponent = (props: P & { className?: string }) => {
    const componentName =
      WrappedComponent.displayName || WrappedComponent.name;

    const componentClassName = componentName ? `c-${componentName}` : '';

    const renderWrappedComponent = () => (
      <WrappedComponent
        {...props}
        componentClassName={componentClassName}
      />
    );

    if (!isElementWrapped) {
      return renderWrappedComponent();
    }

    return (
      <div className={classNames(componentClassName, props.className)}>
        {renderWrappedComponent()}
      </div>
    );
  };

  return WrapperComponent;
};

export default withComponentClassName;
